:root {
  --primary-color: #2f4cdd;
  --grey-color: #2f4cdd14;
  --para-color: #91979c;
}
$para-clr: #91979c;
$btn-completed: rgb(40, 181, 40);
$blackcolor1: #5a5c69;
$grey-color: #2f4cdd14;
$primary-color: var(--primary-color);
$primary_light: #46992414;
$whitecolor: #fff;
$blackcolor: #000;
$darkmode-bg: #38424c;
$darkmode-primary: #434f5a;
$darkmode-sidebar-footer-ckeditor: #343d45;
$lightmode-primary: var(--primary-color);
$text_primary_color: #002033;
$text_secondary_color: #002033;
$text_white_dullcolor: #e3e3e3;
$text_succes_color: #155724;
$text_succes_backcolor: #d4edda;
$text_danger_color: #721c24;
$text_danger_backcolor: #f8d7da;
$text_sidebar_textcolor: #e0384f;
$text_sidebar_inactivetextcolor: #969ba0;

// Admin Responsive start
