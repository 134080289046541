@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --background-color: #6b676f;
  --grey-color: #2f4cdd14;
  --para-color: #91979c;
  --gredient-color: #656464;
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}
$background: #6b676f;
$white-clr: #fff;
$drk-btn: #2f4cdd14;
$btn-clr: #fff;

.bgg_dark {
  background: $background;
  color: $white-clr;
  
}
