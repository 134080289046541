@import "~@angular/material/theming";
@import "~@angular/material/theming";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --primary-color: #6b676f;
  --danger: #ff0000eb;
  --grey-color: #2f4cdd14;
  --para-color: #91979c;
  --gredient-color: #656464;
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}

* {
  outline: none !important;
}
.NgxEditor__Wrapper {
  height: 200px;
}

$danger: #ff0000eb;
$para-clr: #91979c;
$btn-completed: rgb(40, 181, 40);
$blackcolor1: #5a5c69;
$blackcolor2: #6418c3;

$grey-color: #f3eaff;
$gredinet-color: #34e6703d;
$gredinet-color: var(--gredient-color);
$primary-color: var(--primary-color);
$primary_light: #46992414;
$whitecolor: #fff;
$blackcolor: #000;
$darkmode-bg: #38424c;
$darkmode-primary: #434f5a;
$darkmode-sidebar-footer-ckeditor: #343d45;
$lightmode-primary: var(--primary-color);
$text_primary_color: #002033;
$text_secondary_color: #002033;
$text_white_dullcolor: #e3e3e3;
$text_succes_color: #155724;
$text_succes_backcolor: #d4edda;
$text_danger_color: #721c24;
$text_danger_backcolor: #f8d7da;
$text_sidebar_textcolor: #e0384f;
$text_sidebar_inactivetextcolor: #969ba0;
$grn_btn: #38e25d;
// Custom Theming for Angular Material// For more information:https://material.angular.io/guide/theming// Plus imports for other components in your app.// Include the common styles for Angular Material. We include this here so that you only// have to load a single css file for Angular Material in your app.// Be sure that you only ever include this mixin once!@include mat-core();// Define the palettes for your theme using the Material Design palettes available in palette.scss// (imported above). For each palette, you can optionally specify a default, lighter, and darker// hue. Available color palettes:https://material.io/design/color/$apptunix-admin-primary:mat-palette($mat-indigo);$apptunix-admin-accent:mat-palette($mat-pink, A200, A100, A400);// The warn palette is optional (defaults to red).$apptunix-admin-warn:mat-palette($mat-red);// Create the theme object (a Sass map containing all of the palettes).$apptunix-admin-theme:mat-light-theme($apptunix-admin-primary,$apptunix-admin-accent,$apptunix-admin-warn);html {-webkit-transition:background-color 1s ease-out;-moz-transition:background-color 1s ease-out;-o-transition:background-color 1s ease-out;transition:background-color 1s ease-out;}
html {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
    padding: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
  }
}
.btn {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.pac-container {
  z-index: 100000 !important;
}

div#wrapper {
  position: relative;
}
app-footer {
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  line-height: unset;
}

body {
  background: #fbfbfb;
  font-family: "Poppins", sans-serif !important;
}

.dsh_crd_bx .dsh_bx .dsh_cnt p span {
  display: inline-block;
  color: #202020;
  font-size: 18px;
  font-weight: 400;
  margin: 0px 10px;
}

.dsh_bx .dsh_btm .dsh_cnt span b {
  color: $grn_btn;
  font-weight: 500;
}

li {
  list-style: none !important;
}

mat-dialog-container {
  min-width: 545px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.elipises .drpdwn_action.dropdown-menu.h-auto.show ul {
  margin: 0 !important;
}

.str_cate_dv .category_tabs .category_layout {
  padding: 10px 0 20px !important;
}

.drier_innerinfor p.email {
  font-size: 12px !important;
}

.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden !important;
}

.btn-cancelled {
  color: rgb(235, 19, 19);
}

.btn_bck_cancelled {
  background-color: rgb(235, 19, 19);
}

.btn-completed {
  color: $btn-completed;
}

a,
button {
  cursor: pointer;
}
.web_btn {
  display: block;
}
.cmp {
  padding: 10px 0px;
}

img,
.sidebar-brand-icon figure img {
  max-width: 100%;
}

.scllst_bdy {
  overflow-x: auto;
}

.lst_bdyscl::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.product_action {
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.lst_bdyscl::-webkit-scrollbar-thumb {
  background: #888;
}

.card-header {
  padding: 0 !important;
}

.lst_bdyscl::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.drpdwn_action.dropdown-menu.h-auto.side.cstmm.show {
  transform: translate3d(-153px, 25px, 0px) !important;
}

.ag_tabs {
  position: relative;
  overflow: hidden;
}

.order_inner_detail h4 {
  margin-bottom: 5px;
}

.table_inner h3 {
  margin-bottom: 5px;
}

.drier_innerinfor h3,
ul,
p {
  margin-bottom: 0 !important;
}

.table_inner .block_button {
  margin-top: 10px;
}

.radius {
  border-radius: 5px;
  overflow: hidden;
}

a:hover {
  text-decoration: none;
}

.ngx-pagination .current {
  background: $primary-color !important;
}

.btn-primary:hover {
  background-color: $primary-color;
  border-color: $primary-color;
}
.ltg_ds_sdbr li.nav-item a span {
  display: block;
}

.order_dtails ul,
.amount h3,
.table-responsive .combo_action.dropdown.show ul,
.cat_ui .dropdown.show ul {
  margin: 0px;
}

button {
  border: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

button,
.dropdown-menu.dropdown-mes.dropdown-menu-right.shadow.animated--grow-in,
.common-btn {
  border: none;
}

.ltg_ds_sdbr li {
  margin-top: 2px;
}

.btn:focus,
.btn.focus {
  box-shadow: none !important;
}

.mat-calendar-body-cell-content {
  border-style: none !important;
}

.tittle_outer {
  margin-bottom: 10px;
}

.btn-primary,
.h5,
.h4 {
  color: #fff;
}

.add_mrebx {
  color: #e0384f;
  margin: 0px 5px;
  cursor: pointer;
}

label,
.navbar i.fa.fa-bars {
  color: #7f8c8d;
}

.user_add_button.dropdown .add_brand {
  transform: translate(0px, -7px);
}

li.nav-item a span,
li.nav-item a,
.user_title h6,
.mat-tab-label-active,
.admin_setting_titlemain,
.admin_setting_titlemain h2,
.main_admin_details h2 span,
.dropdown-menu.show a,
a,
a:hover {
  color: #000000e3;
}

.admin_setting mat-tab-header.mat-tab-header {
  width: 20%;
}

.btn-primary {
  background-color: var(--primary-color) !important;
}

.tblovrflw {
  max-height: 480px;
  overflow-y: auto;
}

ul.nav.nav-tabs,
.table-responsive thead tr,
.image_placeholder,
.list_thding,
.list_table .table_heading {
  background: #ffffff;
}

input::placeholder,
textarea::placeholder {
  color: #c1c1c1 !important;
  opacity: 1 !important;
}

.disable_class,
.disable_class_2,
.dimg,
.sidebar-dark .nav-item.active .nav-link {
  background-color: $grey-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #8080807d !important;
}

.card_flex .hvrcursor:nth-child(1) .card-body {
  background: #8adb36bf;
}

.card_flex .hvrcursor:nth-child(2) .card-body {
  background: #ff816f;
}

.card_flex .hvrcursor:nth-child(3) .card-body {
  background: #90c8fc;
}

.card_flex .hvrcursor:nth-child(4) .card-body {
  background: #76c5b2;
}

.card_flex .hvrcursor:nth-child(5) .card-body {
  background: #0085cb;
}

.card_flex .hvrcursor:nth-child(6) .card-body {
  background: #54c6e5;
}

.card_flex .hvrcursor:nth-child(7) .card-body {
  background: #8d6f4abf;
}

.card_flex .hvrcursor:nth-child(8) .card-body {
  background: #00398edb;
}

.card_flex .hvrcursor:nth-child(9) .card-body {
  background: #5552de;
}

.card_flex .hvrcursor:nth-child(10) .card-body {
  background: #b7d636;
}

.card_flex .hvrcursor:nth-child(11) .card-body {
  background: #ee4e4e;
}

.card_flex .hvrcursor:nth-child(12) .card-body {
  background: #efb13f;
}

.card_flex .hvrcursor:nth-child(13) .card-body {
  background: #ff5f3b;
}

.card_flex .hvrcursor:nth-child(14) .card-body {
  background: #ff6a81;
}

.filter-prd .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: unset !important;
}

.drop svg {
  width: 12px;
  height: 12px;
}

.innr_cntr_content span.du_heading {
  font-size: 16px;
  color: #635b5b;
}

.innr_slot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fdx_slots button {
  width: 15%;
  font-size: 14px;
}
.outtr-bx {
  height: 316px;
  max-height: 400px;
}

.fa_outer {
  background: #2f98d4;
  padding: 8px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fa_outer i.fas.fa-plus {
  color: #fff;
}

.slot_data,
.web_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.rev_tbl .border_pd tbody td {
  padding: 10px;
}

.store_outr {
  background: #dedede26;
  padding: 10px;
  border-radius: 6px;
}

.inner_frst {
  width: 40%;
}

.inner_thrd {
  width: 8%;
}

.store_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.store_wrap .lft {
  width: 60%;
}

.store_wrap .right {
  width: 28%;
  button {
    margin: 0px 10px;
  }
}

// modal css end
.catui_row,
.driver_tracing_deails img,
.tracking_right_detils ul li,
.modal.left .modal-dialog,
.bottom_cate_data .category_layout label.mat-checkbox-layout,
.modal.right .modal-dialog,
.modal.left .modal-content,
.bby,
.header-right,
.bottom_cate_data .category_layout .mat-checkbox,
.logo-settings,
.modal.right .modal-content,
table,
.invalid-feedback,
.logo-settings,
.theme_layout.theme_mode.mobile_mode ul li:first-child,
.table-body,
.tabbing_top_innerleft,
.driver_tracing_deails,
.cover_img,
.cover_img img,
.cover_banner img,
.order_no_rcrds,
.tabbing_top_innerright,
.user_img img,
.driver_innerdetails,
.allclc-btn,
.botton_right_buttons,
.doc_name,
.rating_outer,
.ng_first_tab,
.feature_main_outer,
.cat_ui .crd_padd,
.request_ride_inner form,
.total_bill,
.ordered_items,
.main_heading,
.tgl-skewed + .tgl-btn:after,
.tgl-skewed + .tgl-btn:before,
.tgl-flip + .tgl-btn:after,
.tgl-flip + .tgl-btn:before,
.seo_left,
.user_title_inner,
.theme_layout li i,
.request_ride_inner .title_bar,
.sdirver_Details,
.driver_customer_details .tab_innrdv,
.order_image figure img,
.manage_in_title,
.modal.left .modal-content,
.payment_box,
.main_heading,
.order_image figure img,
.modal.right .modal-content,
.filter-main.full-filter,
.select-category select,
.dbbt_card h6,
.sidebar-brand-icon,
.order_dtails ul li.all_block,
.payment_box,
.web_fdx,
body.boxed_layout .topbar,
.card-admin_setting,
.up_file input,
.titls,
.bigholderhor,
.invalid-feedback,
.day_time .items ul,
.admin_setting .mat-tab-labels,
.sign_fdx,
.innr_check,
.check_wrap .innr_cntr_content,
.api_fdx .right_api,
.sidebar .nav-item .nav-link,
.innr_social,
.cncl_fdx,
.reson_hd,
.cncl_policy_fdx,
.rows,
.sidebar-dark .nav-item.active .nav-link,
.outer_featured .add_more,
.notifi_outer,
.loginmail_input,
.form-control,
.image_placeholder,
.drier_img img,
.up_file,
.mat-form-field,
.new-select,
.full-wid button,
.admin_setting .mat-tab-label,
.image_placeholder_showup_file input,
.outer_uploadsshdf label,
.tabss,
.right_login figure,
.right_login figure img,
.admin_setting_title_sub h5,
.image_placeholder_showup_file,
.bigholderhor,
.invalid-feedback,
li.nav-item a span,
.bigholder,
.single_doc,
.dimg img,
.outer_uploadsshdf p,
.image_placeholder,
.image_placeholder_showup_file,
.image_placeholder figure,
.image_placeholder figure img,
.bottom_cate_data .cate_data,
.cus_img {
  width: 100%;
}

.liveTracking ul.innettabss li {
  margin: 0px 15px;
}

.brnd_flex {
  padding: 0px 10px;
}

.cat_ui .crd_padd,
.sidebar .sidebar-brand,
.sidebar hr.sidebar-divide,
.user_details h3,
h4,
.inner_drivercustomer_details ul.tracking_listing,
.order_inner_detail h5 {
  margin: 0 !important;
}

.table_inner {
  margin: 0px 5px;
}

.drier_innerinfor h3,
.main_admin_status h3,
.user_details h5,
.table_inner h3,
.feature_center h6 {
  font-size: 14px;
}

.customer_details .amount,
.driver_customer_details .nav-tabs .nav-link {
  font-size: 15px;
}

i.fa.fa-ellipsis-v {
  color: #7f7f7f;
}

.sdirver_Details,
.form_bg,
.card-admin_setting,
.table_outer_main,
.form-control,
.admin_setting,
.image_placeholder,
.outer_uploadsshdf label,
.liveTracking .tleft,
.inner_drivercustomer_details,
.mat-datepicker-content,
.mat-dialog-container,
.mat-select-panel,
span.import_icon i:before,
.outer_uploadsshdf p {
  background: #fff;
}

.child_svg a,
.mrcrd_hd h1,
.table td,
.card .table td,
.card .dataTable td,
.card .dataTable th,
.infodd h3 {
  color: #91979c;
}

.table thead th {
  color: #000000ad;
}

label,
.tabbing_top_innerleft h3,
.main_admin_details h2 {
  font-size: 13px;
  font-weight: 600;
}

.single_doc_inner,
.search input,
.form-control,
.cate_data.checked_div .checkbx,
.profilein_image,
.form-control,
.outer_uploadsshdf,
.single_doc,
.chrome-picker {
  border: 1px solid #00000063 !important;
}
.catdata_name span.checkbx {
  border: 1px solid #bdb7b7 !important;
}

ul.navbar-nav.bg-gradient-primary.sidebar.sidebar-dark.accordion.toggled
  .nav-item
  a
  span,
.child_svg,
span.import_icon i:before {
  margin-left: 0;
}

.form-control,
.dtl_data span,
.right_usr ul li h5 span,
.to-message,
.grdn_btn .tooltip,
.lte8 .grdn_btn:hover .tooltip {
  display: block;
}

.sidebar.toggled {
  overflow: visible;
  width: 70px;
}

.sidebar.toggled .nav-item .nav-link {
  text-align: center;
}

.user_details a {
  font-size: 13px;
  color: $para-clr;
  font-weight: 500;
}

.user_details h4 {
  line-height: 18px;
  color: $para-clr;
  font-size: 12px;
}

figure {
  margin: 0;
}

.documnet_con,
.card_tg_list .tg-list,
.feature_center,
.nofication_outer,
.notification_tbl_wrp .admin_mrchnt_fdx,
.admin_setting_title_sub,
.option_select,
.Tookan_fdx,
.vdo_icon,
.outer_wrap.Top_head,
.feature_main_outer,
.feature_card .feature_top,
.single_doc,
.get_content_fdx,
.domains_fdx,
.add_update_outr .delivery_wrap,
.ng_first_tab .delivery_wrap,
.table_box,
.opn_close_main,
.rating_outer,
.feature_card .feature_top figure,
.reminder_togle,
.child_svg,
.cross_button,
.sidebar-brand-text figure,
.slot_fdx,
.ul_fdx,
.store_status,
.seo_wrp.seo_fdx,
.seo_wrp.innr_social,
.dftlp,
.catbtn_ui.user_add_button,
.box_flex,
.collapse_data,
.collapse_right,
.tag_btn,
.ref_btn_fdx,
.filters_outer,
.bottom_cate_data .category_layout label.mat-checkbox-layou,
.cncl_wrp.cncl_fdx,
.reson_hd.rsn_outer,
.admin_setting .mat-tab-labels,
.modal-dialog,
.backgrnd .mat-form-field-flex,
.rows,
.main_right_cntnt.inr_cntnt,
.nav-link,
.back_btn_setings,
.card-hesaderpy,
.user_filter,
.ref_main,
.btm_ref_wrp .referral_fdx,
.cncl_policy_fdx,
.filters_outer,
.number_with_country.login_email.country_code_div.code-country,
.card_flex,
.settings_wrp.sign_fdx,
.search,
.d_form_fdx,
.sub_status,
.cdk-overlay-pane.owl-dt-popup,
.number_with_country,
.check_wrap.innr_check,
// #wrapper,
.web_fdx,
.time_slot,
.day_time .items ul,
.day_time .items,
.str_open_cls.notes,
.tab_wrap,
.check_wrap.innr_field,
.catui_row,
.filter-drpdwn,
.product_action,
.stars form,
.text-right.geofence,
.col-md-6.user_serch_btn,
.three_formgroup,
.addbtn_action ul,
.cover_banner,
.cover_img,
.prd_info,
.theme_layout ul,
.allclc-btn,
.navbar-nav,
.botton_right_buttons,
a#alertsDropdown,
a#messagesDropdown,
.admin_setting .mat-tab-group,
.Merchant_outer_cell,
.Merchant_outer_viewo,
.ortehss,
.order_dtails ul,
.payment_box,
.cash_payment,
.main_heading,
.prd_details,
.table_driver .infod:hover .popopen,
.infod span,
.driver_customer_details .tab-pane,
.catbtn_ui.ctr_imp_bttns,
.main_admincusdrdetail,
.date-filter-wrap,
.search-filter-btn,
.cancellation_wrp.Reasonheafd,
.table_box,
.product_action button,
.switch,
.selector_div,
.header-right,
.category_layout,
.sdirver_Details,
.ordered_tiems,
.bby,
.user_add_button,
.headfcancellation_fdx,
.key_wrap.key_fdx,
.key_fdx .map_toggle,
.lgn_page_mn,
.tab_wrap,
.user_title,
.api_fdx,
.api_fdx .apikey,
.outer_featured .add_more,
.user_detail_fair,
.trip_fare ul li,
.dta_right,
.notifi_outer .notifi_innr,
.remove_doc_cont,
.delivery_track_add,
.request_ride_inner .liveTracking,
.amount,
.driver_innerdetails,
.driver_tracing_deails,
#myModal2 .modal-header,
ul.innettabss,
.loader_inner,
button.close,
.request_ride_inner .user_detail_fair,
.sidebar .nav-item .nav-link,
button.close,
.trip_fare ul li,
.ordered_items,
.ordered_tiems,
.back_btn_setings .pagination_ui,
.manage_in_title,
.ordered_tiems,
.common_main_heading,
.loader2_main,
.loader2_top,
.catbtn_ui.ctr_imp_bttns span,
.loader_inner,
.cash_payment,
.col-card,
.payment_box,
.loader2_main,
.loader2_top,
.driver_customer_details ul.nav.nav-tabs,
.cash_payment,
.driver_customer_details .tab_innrdv,
.common_main_heading,
.image_placeholder_showup_file,
.image_placeholder,
.request_ride_inner .form-group,
.table_right,
.user_detail_fair,
.trip_fare ul li,
.admin-table .table_box .outer_uploadsshdf label,
.admin_setting_title_sub h5 .lft_login,
.main_admin_details,
.drier_info,
.main_heading,
.upper_details,
.upper_details_inner,
.tabbing_top_heading,
.order_dtails ul,
li.all_block .order_inner_detail,
.right_login figure,
.titls,
.user_details a.call_nbumer,
.zone_panel,
.topbar,
.Merchant_outer,
.modal-header_ff,
.profilein_image,
.close_bb button.close,
.table_heading_box,
.revnue_fdx,
.up_file,
.single_doc .form-group label,
.catbtn_ui,
.innr_social,
.dlld li,
.outer_uploadsshdf label,
.dftlp,
label.switch,
.customer_details .amount,
.outer_uploadsshdf p,
.image_placeholder,
.image_placeholder_showup_file,
.bottom_cate_data,
.child_svg a,
.cstmr_chcbx,
.filters_outer i.fa.fa-th,
i.fa .fa-list,
.liveTracking .map_track,
.doc_container {
  display: flex;
}

.nav-link,
.upper_details.lower_details,
.backgrnd .mat-form-field-flex,
.documnet_con,
.liveTracking,
.Reasonheafd,
.catbtn_ui,
.Merchant_outer,
.modal-header_ff,
.admin_setting .mat-tab-group {
  flex-direction: row;
}

.child_svg span,
.tracking_right_detils,
.tracking_listing li,
.btn-primary,
.table-responsive th {
  white-space: nowrap;
}

.date-filter-wrap .filter_search {
  margin: 0px 8px 0px 0px;
}

.upper_details,
.selector_div,
.Merchant_outer_viewo,
.table_right,
.payment_box,
.cash_payment,
.navbar-nav,
.payment_box,
.cash_payment,
.dftlp,
.loader2_main,
.loader2_main,
.upper_details,
.ordered_tiems,
.ordered_tiems,
.admin_setting .mat-tab-labels,
.ordered_tiems,
.single_doc,
.bottom_cate_data,
.dftlp,
.clm_count,
.elipises .combo_action.dropdown.show ul,
.up_file.up_single,
.dsh_dtls {
  flex-direction: column;
}

.table_box,
.up_file,
.catbtn_ui,
.close_bb button.close,
.table_heading_box,
li.all_block .order_inner_detail,
.child_svg,
.table_right,
.cash_payment,
.common_main_heading,
.loader2_main,
.loader_inner,
button.close,
.request_ride_inner .form-group,
.filters_outer,
.user_detail_fair,
.trip_fare ul li,
.ordered_items,
.main_admin_details,
.main_heading,
.admin_setting_title_sub,
.user_details a.call_nbumer,
.driver_innerdetails,
.driver_tracing_deails,
.nav-link,
#myModal2 .modal-header,
.delivery_track_add,
.request_ride_inner .form-group,
.user_detail_fair,
.trip_fare ul li,
.dftlp,
.dta_right,
.tab_wrap,
.upper_details.lower_details,
.search-filter-btn,
button.close,
.vdo_icon,
.filters_outer,
.product_action button,
.switch,
.header-right,
.category_layout,
.infod span,
.sub_status,
.drier_info,
.prd_info,
.theme_layout ul,
.botton_right_buttons,
a#alertsDropdown,
a#messagesDropdown,
.navbar-expand .navbar-nav,
.loader2_main,
.loader_inner,
.admin-table .table_box .table_box,
.ortehss,
.main_heading,
.cash_payment,
.common_main_heading,
.row.for_center,
.image_placeholder_showup_file,
.image_placeholder,
.request_ride_inner .form-group,
.user_detail_fair,
.outer_wrap.Top_head,
.col-card,
.trip_fare ul li,
.user_title,
.product_action,
.stars form,
.text-right.geofence,
.sidebar-brand-icon .sidebar.toggled .nav-link,
.web_fdx,
.day_time.items,
.tab_wrap,
.d_form_fdx,
.option_select,
.sidebar .nav-item .nav-link,
.number_with_country,
.user_filter,
.modal-dialog,
.backgrnd .mat-form-field-flex,
.feature_main_outer figure,
.pagination_ui,
.rows,
.bottom_cate_data .category_layout label.mat-checkbox-layout,
.str_cate_dv .category_layout,
.profilein_image .tabbing_top_heading,
.box_flex,
.collapse_data,
.collapse_right,
.cross_button,
.sidebar-brand-text figure,
.admin_setting .mat-tab-label,
.slot_fdx,
.ul_fdx,
.store_status,
.opn_close_main,
.get_content_fdx,
.domains_fdx,
.notifi_outer .notifi_innr,
.remove_doc_cont,
.feature_card .feature_top,
.card_tg_list .tg-list,
.feature_center,
.notification_tbl_wrp .admin_mrchnt_fdx,
.dimg,
.seo_wrp .seo_fdx,
.ref_main,
.btm_ref_wrp .referral_fdx,
.ref_btn_fdx,
.main_right_cntnt.inr_cntnt,
.innr_cntnt.map_toggle,
.revnue_fdx,
.reson_hd.rsn_outer,
.cncl_policy_fdx,
.check_wrap .innr_check,
.innr_field,
.Reasonheafd,
.headfcancellation_fdx,
.api_fdx .apikey .dlld,
label.switch,
.dlld li,
.image_placeholder,
.image_placeholder_showup_file,
.dftlp,
.amount,
.zone_panel,
.modal_set {
  align-items: center;
}

.table_box,
.ordered_tiems,
.table_heading_box,
.cash_payment,
.driver_customer_details ul.nav.nav-tabs,
.driver_customer_details ul.nav.nav-tabs,
.user_detail_fair,
.ordered_tiems,
.driver_customer_details ul.nav.nav-tabs,
.child_svg,
.upper_details_inner,
.user_details a.call_nbumer,
.driver_tracing_deails,
.user_detail_fair,
.ordered_tiems,
.js-left,
.prd_info,
.cash_payment,
.user_detail_fair,
.stars form,
.time_slot,
.card_flex,
.admin_setting .mat-tab-labels,
.bottom_cate_data .category_layout label.mat-checkbox-layout,
.slot_fdx,
.table_box,
.Tookan_fdx,
.main_right_cntnt .inr_cntnt,
.reson_hd.rsn_outer,
.sidebar .nav-item .nav-link,
.zone_panel,
.nofication_outer,
.drier_info,
.driver_customer_details ul.nav.nav-tabs,
.single_doc,
.doc_container {
  justify-content: flex-start !important;
}

.catbtn_ui.ctr_imp_bttns,
.search-filter-btn,
.switch,
.allclc-btn,
.cdk-overlay-pane.owl-dt-popup,
.catbtn_ui.user_add_button,
.box_flex,
.tag_btn,
.pagination_ui,
label.switch {
  justify-content: flex-end;
}

.lft_login,
.image_placeholder .sidebar.toggled .nav-link,
.remove_doc_cont,
.image_placeholder_showup_file,
.image_placeholder,
.day_time .items ul .Tookan_fdx .vdo_icon,
.feature_card figure,
.dimg,
.col-card,
.sidebar-brand-icon,
.table_right,
.image_placeholder,
.image_placeholder_showup_file {
  justify-content: center;
}

.filters_outer,
.catbtn_ui,
.topbar,
.Merchant_outer,
.modal-header_ff,
.titls,
.order_dtails ul,
.admin_setting_title_sub,
li.all_block .order_inner_detail,
.payment_box,
.cash_payment,
.driver_customer_details .tab_innrdv,
.filters_outer,
.common_main_heading,
.loader2_main,
.manage_in_title,
.main_admin_details,
#wrapper,
.main_heading,
.upper_details,
.driver_innerdetails,
.delivery_track_add,
.dta_right,
.tab_wrap,
.catui_row,
.bby,
.main_admincusdrdetail,
.category_layout,
.prd_details,
.driver_customer_details .tab-pane,
.ordered_items,
.addbtn_action ul,
.ortehss,
.order_dtails ul,
.payment_box,
.main_heading,
.cash_payment,
.common_main_heading,
.loader2_main,
.user_title,
.filter-drpdwn,
.text-right.geofence,
.three_formgroup,
.web_fdx,
.d_form_fdx,
.sub_status,
.table_box,
.backgrnd .mat-form-field-flex,
.rows,
.collapse_data,
.collapse_right,
.ul_fdx,
.opn_close_main,
.outer_wrap .Top_head,
.admin_setting .mat-tab-group,
.get_content_fdx,
.domains_fdx,
.add_update_outr.delivery_wrap,
.ng_first_tab.delivery_wrap,
.feature_top,
.tabbing_top_heading,
.feature_center,
.notification_tbl_wrp .admin_mrchnt_fdx,
.seo_wrp .seo_fdx,
.zone_panel,
.innr_social,
.ref_main,
.btm_ref_wrp .referral_fdx,
.cncl_wrp .cncl_fdx,
.cncl_policy_fdx,
.outer_uploadsshdf label,
.sign_fdx,
.check_wrap.innr_check,
.check_wrap.innr_field,
.cancellation_wrp.Reasonheafd,
.admin-table .table_box,
.cancellation_wrp.headfcancellation_fdx,
.key_wrap .key_fdx,
.api_fdx,
.api_fdx .apikey,
.revnue_fdx,
.dlld,
.single_doc .form-group label,
.dlld li,
.reminder_togle,
.outer_uploadsshdf p,
.cstmr_chcbx,
.user_detail_fair {
  justify-content: space-between;
}

.doc_container,
.order_dtails ul,
.theme_layout ul,
.order_dtails ul,
.filter-drpdwn,
.mrdb_fx,
.table_driver .infod:hover .popopen,
.nofication_outer,
.driver_customer_details .tab-pane,
.web_fdx,
.card_flex,
.feature_main_outer,
.driver_customer_details .tab_innrdv {
  flex-wrap: wrap;
}

.cross_button,
.tgl,
.tgl + .tgl-btn:before,
.bigpro.outer_uploadsshdf label,
.theme_layout.theme_mode.mobile_mode i,
.navbar-nav.bg-gradient-primary.sidebar.sidebar-dark.accordion
  .sidebar-brand-icon
  svg,
.bottom_cate_data .checkbx,
.texibookigs .pagination_ui,
.basicDeliveryApp_booking .pagination_ui,
.shuttle_bookib .pagination_ui,
.shuttle_bookib,
.basicDeliveryApp_booking,
.texibookigs .filter-prd,
.theme_layout.mobile_mode,
.fa_drop,
.product-action .dropdown-toggle:after,
img.mini_sidebar,
.table_right img,
.toggled .sidebar-brand-icon img,
.select_layout.layout1 i,
.boxed_layout .select_layout.layout2 i,
.admin_setting .mat-tab-header .mat-ink-bar,
.table_driver .popopen,
.toggled .sidebar-brand-icon img.mini_sidebar,
.table_right figure,
button#sidebarToggle,
ul.navbar-nav.bg-gradient-primary.sidebar.sidebar-dark.accordion.toggled
  .nav-item
  a
  span,
.user_add_button.dropdown .dropdown-toggle:after,
.catbtn_ui .dropdown-toggle:after,
.mat-ripple-element,
.bigpro.outer_uploadsshdf p,
.card_tg_list .tgl {
  display: none;
}

.toggled img.mini_sidebar,
.d-md-none {
  display: block !important;
}

.bottom_cate_data {
  align-items: flex-start;
}

.card_flex .hvrcursor:nth-child(4),
.card_flex .hvrcursor:nth-child(8),
.card_flex .hvrcursor:nth-child(12),
.card_flex .hvrcursor:nth-child(16) {
  margin-right: 0px;
}

.inner_drivercustomer_details,
.listall li,
.table-body,
tr:hover.liveTracking .tleft,
.admin_setting .mat-tab-header,
.card,
.form_bg,
.card-admin_setting,
.card_flex .hvrcursor .card-body,
.feature_card {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 4%) !important;
}

.sidebar.toggled .nav-link,
.btn_ellipse .drpdwn_action,
.dta_left h6,
.order_alllft,
span.cash_delivery,
.filter-main,
.drpdwn_action li,
.drier_innerinfor,
.order_alllft,
.request_ride_inner .title_bar,
.card .table th,
.card .dataTable td,
.sidebar .nav-item .nav-link,
.sidebar-brand-icon,
.table_inner.width_increase,
.filter-header,
.order-module-select,
.card .dataTable th {
  text-align: left;
}
/*tooltip*/

.word {
  display: inline-block;
  position: relative;
  background-color: lightyellow;
}

.definition {
  display: none;
}

.word:hover > .definition {
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: calc(50%);
  transform: translateX(-50%);
  padding: 3px;
  border: 1px solid blue;
  white-space: nowrap;
}
/*tooltip end*/

.filter-prd,
.geofence_btn,
span.amount_paid,
.allclc-btn,
.botton_right_buttons {
  text-align: right;
}

.drier_innerinfor p {
  word-break: break-word;
}

.card,
.mat-form-field-infix {
  border: unset !important;
}

.table.table th,
.table thead th {
  padding: 10px 15px;
  border: unset;
}

.card .dataTable td,
.card .table td,
.card .dataTable th {
  padding: 8px 15px;
  vertical-align: middle;
  border-bottom: 2px solid #00000005 !important;
  line-height: normal;
  border: unset;
  margin: 0px;
}

.mat-checkbox,
.dimg,
.driver_tracing_deails figure,
.modal-body .blue_circle,
.modal-body .black_circle,
.lft,
span.psearch_icon,
.mr,
.catbtn_ui.user_add_button button,
.sidebar li img,
.single_doc:nth-child(3) {
  margin-right: 10px;
}
.search,
.catbtn_ui,
.search.wrp_respsv,
.bon .mat-dialog-actions {
  gap: 6px;
}
.btn_grp.drx_btn {
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pfl_img {
  margin-right: 40px;
}
.catbtn_ui .dropdown-menu.show {
  transform: translate3d(-114px, 34px, 0px) !important;
  padding: 5px;
}
.dropdown-menu .dropdown-item {
  padding: 2px 6px !important;
}

.opn_close_main,
.rating_outer,
.search-filter-btn,
.new_spc,
.app_introimg_sc,
.d_form_fdx,
.card_flex,
.texibookigs,
.basicDeliveryApp_booking,
.shuttle_bookib {
  margin-top: 10px;
}

.innettabss li,
.catdata_name span.checkbx {
  margin-right: 5px;
}

.upper_details_inner.user_details .driver_details {
  margin-left: 10px;
}

.main_admin_status button {
  margin-left: 5px;
}
.main_admin_status button:first-child {
  margin-left: 0px;
}

.padding-y {
  padding: 12px 0px;
}

.tracking_right_detils ul li,
.delivery_sec ul li span,
.cash_delivery {
  font-size: 14px;
  font-weight: 500;
  color: $para-clr;
}

.h5,
.h4,
.table,
label,
.navbar-nav,
.card-header,
.form-group.form-actions.add_edit,
.main_heading h1,
.form-group.form-actions.cms_forms,
.pagination_ui .ngx-pagination,
.bottom_cate_data .category_layout label.mat-checkbox-layout,
.main_heading h1,
figure {
  margin-bottom: 0 !important;
}

.cash_mo,
.rating_outer,
.card_tg_list.tg-list,
.tgl-skewed + .tgl-btn:after,
.tgl-skewed + .tgl-btn:before,
.rsn_outer.rsn_optn,
.rsn_optn,
.tgl-flip + .tgl-btn:after,
.tgl-flip + .tgl-btn:before,
.notes li input,
.fixed-delete-cross,
.fixed-delete-cross,
.logo-settings,
.rev_wrp .rev_outer .revenue_tbl td,
.fixed-delete,
.fixed-delete-cross,
.lang-selecter,
.lang-selecter option,
.table_right,
.mat-dialog-title,
.table_right h5,
.sub_titl h2 span,
.time_slot .sub_titl h2 span,
.theme_layout li i,
.total_bill,
.dbbt_card h6,
.mat-dialog-title,
.order_inner_detail,
.description_name h3,
.remove_doc_cont,
.dimg,
.fixed-delete1,
.fixed-delete-cross1,
.cstmr_chcbx,
.table thead th,
.table.table th,
.card .table td,
.table td,
.zone_panel .mat-select-value {
  text-align: center !important;
}

.listall li,
.mb10,
.inner_drivercustomer_details,
.table_outer_main,
.single_doc,
.filter-prd,
.card_flex .hvrcursor {
  margin-bottom: 10px;
}

ul.navbar-nav.ryt_nvbr.ntf_prfle p {
  margin-right: 10px !important;
}

.rev_map,
.notification_tbl_wrp .admin_mrchnt_fdx,
.tag_btn,
.drop_wrp label,
.table-responsive .block_button.unblock_button {
  margin: 10px 0px;
}

table.table.table-bordered td img {
  border-radius: 10px;
}

.tabbing_top_innerleft
  .tabbing_top_heading
  h3
  .main_admin_details
  .list_booking_details
  h2 {
  color: #000000d6;
  font-weight: 600;
}

.main_admin_details span.main_heading {
  color: #000000d6;
  font-weight: 600;
  font-size: 14px;
}

.zone_panel .mat-select-value {
  width: 90% !important;
}

.main_admin_details h2 :nth-child(2) {
  font-size: 12px !important;
  color: $para-clr;
}

.main_admin_details p,
.main_admin_details span {
  color: $para-clr;
}

.mat-radio-button,
.admin_setting_title h6,
li.all_block {
  margin-bottom: 5px;
}

.right_login figure,
.right_login figure img,
.card_flex .hvrcursor .card_flex .card .align-items-center,
.image_placeholder_showup_file,
.image_placeholder_showup_file input,
.image_placeholder,
.drier_img img,
.bg-gradient-primary,
.sidebar-brand-icon figure img,
.dimg img,
.image_placeholder,
.image_placeholder figure,
.image_placeholder figure img,
.admin_setting .mat-tab-body-content,
.user_img img {
  height: 100%;
}

.card_flex .hvrcursor .card-body .col.ml-3 {
  margin-left: 60px !important;
}

.card_flex .hvrcursor .card-body {
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
}

.card_flex .hvrcursor .card {
  height: 100%;
}

.card_flex .hvrcursor {
  width: 24%;
  margin: 3px 1px 5px 8px;
  min-height: 70px;
}

.sidebar-brand-icon {
  figure {
    width: 150px;
    height: 100%;
    max-height: 70px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  button {
    padding: 0px;
    color: #202020;
    i {
      font-size: 25px;
      margin: 8px 0px 0px 5px;
    }
  }
}

.ltg_ds_sdbr h3 {
  padding: 15px 20px;
  color: #c7c7c7;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
}

.header-right {
  display: flex;
  justify-content: space-between;
}

.table_id {
  padding: 15px;
}

.str_cate_dv .category_layout,
.cthe,
.single_doc_inner,
.form_bg,
.form-control,
.search input,
.sdirver_Details,
.badb_row,
.my_sales_card,
.delete-span-cat,
.filter-header,
.pad10 {
  padding: 10px;
}

.card-body.new--padd.pdr,
.profile_inner {
  padding: 20px;
}

.sidebar.toggled .nav-link,
.loader_inner,
.manage_in_title,
.admin_setting_title p,
.pad-b,
.section_header,
.notification_table td,
.notification_tbl_wrp td,
.cstmr_chcbx,
.copyright,
.admin_setting_title_sub h5 {
  padding: 10px 0;
}

.profilein_imagelist li,
.nofication_outer .inner_box {
  padding: 8px 12px;
}

.single_doc .checkbox_doc_single,
.table_outer_main,
.rev_map,
ul,
.liveTracking .tleft,
.inner_drivercustomer_details,
.card-body,
.card_flex .hvrcursor,
.sidebar .sidebar-brand,
.mat-form-field-appearance-fill .mat-form-field-flex,
.add_driver_outer .mat-form-field-wrapper,
.add_resurant .new-select .mat-form-field-wrapper,
.mat-form-field-wrapper {
  padding: 0 !important;
}
.profile_inner .form-group {
  margin-bottom: 16px;
}

.child_svg {
  padding: 5px 10px;
}
.image_hding {
  font-size: 14px;
  color: #7f8c8d;
  font-weight: 500;
}
.product-action
  .combo_action.dropdown.show
  .drpdwn_action.dropdown-menu.ng-star-inserted.show {
  border-radius: 10px;
}

.bg-white.py-2.collapse-inner.rounded {
  background: rgb(145 158 171 / 6%) !important;
  margin: 0 5px 0 15px;
}

.admin_setting .mat-tab-body-content {
  overflow: unset;
}
.filters_outer {
  padding: 10px 15px;
}

.product-action .combo_action.dropdown i.fa.fa-ellipsis-h {
  transform: rotate(90deg);
  cursor: pointer;
}
.current span {
  font-size: 11px;
}

p,
.child_svg,
.common-btn,
.form-control,
.table td,
.card .table td,
.card .dataTable td,
.card .dataTable th,
.infodd h3,
li.nav-item a span,
.infodd h3,
.col_card .view_outer h6 {
  font-size: 14px;
  font-weight: 500;
}

.ltg_ds_sdbr li.nav-item a span {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.mat-form-field-infix,
.tabbing_top_heading,
.admin-table .table_box,
.bottom_user_cate_data .cate_data,
.inner_drivercustomer_details .upper_details.lower_details {
  padding: 5px 7px !important;
}

.main_admin_details {
  padding: 5px 12px;
}

.cat_ui,
.table-wrap {
  overflow-x: auto;
}

.ng-star-inserted.ngx-dropdown-list-container,
.cat_ui {
  overflow-x: hidden;
}

.feature_center h6 {
  font-weight: bold;
  line-height: normal;
}

h2,
.mrcrd_hd h1,
.free_tdx label {
  font-size: 14px;
}

h4,
.h4,
.infodd h6,
.infodd h5,
ul.tracking_listing li {
  font-size: 14px;
}
.main_admin_details h2 span {
  font-size: 12px;
}

h5,
.h5,
.admin_setting_titlemain,
.admin_setting_titlemain h2 {
  font-size: 12px;
}

h6,
.user_title h6,
span.import_icon i:before,
.main_hding h4,
.navbar i.fa.fa-bars,
.var_close_ico h2 {
  font-size: 20px;
}

.h5,
.h4 {
  line-height: 1.5;
}

.common-btn,
.form-control,
.sdirver_Details,
.cate_data,
.single_doc_inner,
.cdk-overlay-pane,
.lang-selecter,
.feature_card,
.bigholderhor,
td select,
.order_dtails ul li,
.cat_ui .crd_padd,
.remove_doc_cont {
  border-radius: 5px;
}

.liveTracking .tleft,
.inner_drivercustomer_details,
.card_flex .hvrcursor,
.table_outer_main,
mat-ink-bar.mat-ink-bar {
  border-radius: 10px;
}

.catdata_name span.checkbx,
.switch.switch-medium,
label.switch {
  border-radius: 30px;
}

.drpdwn_blc .ngx-dropdown-button {
  border-radius: 0px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  border-width: 5px 5px 0px !important;
  border-color: #6d6c6c transparent !important;
}

mat-ink-bar.mat-ink-bar {
  height: 3px;
}
.clm_count {
  display: flex;
}

.dimg,
.drier_img,
.outer_uploadsshdf.bigpro {
  border-radius: 50%;
}

.drier_innerinfor {
  padding-left: 12px;
}

.infodd h5 {
  font-weight: normal;
}

.user_title h6,
.mrcrd_hd h1,
.h5,
.mrcrd_hd h1,
label,
.driver_customer_details .nav-tabs .nav-link,
.main_admin_details h2 span,
.order_type,
.outer_uploadsshdf p,
.innr_cntr_content span.du_heading,
.var_close_ico h2 {
  font-weight: 500;
}

.card .table th,
.table thead th,
.card .dataTable th {
  font-weight: 700;
}

.table.table th,
.table thead th,
.card .table th {
  font-size: 13px;
}

.child_svg a,
.h4,
.table td,
.table,
.card .table td,
.card .dataTable td,
.form-control,
.customer_details .amount,
.common-btn,
.mat-tab-list .mat-tab-label {
  font-weight: 400 !important;
}

.infodd h3 {
  font-weight: 800;
}

div#cdk-overlay-2 {
  max-width: 350px !important;
}

.dftlp,
.remove_doc_cont,
.col-card,
.image_placeholder_showup_file,
.image_placeholder,
.drpdwn_blc .ngx-dropdown-button,
.outer_uploadsshdf label,
.outer_uploadsshdf p,
.image_placeholder,
.image_placeholder_showup_file,
.dftlp {
  position: absolute;
}

.user_img i:before,
div#accordionExample h2 button,
span.checkbx i,
span.import_icon i:before,
.btn-primary_border,
.sub_main_heading p,
.start_color,
.addbtn_action i:before,
.p-search_btn i:before,
.prd-napc p,
.theme_layout li i,
.category_tabs .nav-item .nav-link,
.scroll-to-top:focus,
.scroll-to-top:hover,
.user_filter.single-filter.for-search i,
.btn-primary_border,
.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after,
.modal-body .request_ride_inner .title_bar span,
.ratings i.rated,
a.dropdown-item i {
  color: $primary_color;
}

.order_type,
.single_doc,
.zone_panel,
.feature_card,
.table_right,
.No_data h3 {
  padding: 10px;
}

.image_placeholder,
.default_viewer .image_placeholder {
  z-index: 0;
}

.showProductSearch,
.image_placeholder figure,
.withinputs .image_placeholder,
.image_placeholder_showup_file {
  z-index: 1;
}

.outer_uploadsshdf label,
thead,
.outer_uploadsshdf p {
  z-index: 2;
}

.dropdown-menu.show a,
.fixed-delete-cross1,
span.import_icon i:before,
.fixed-delete1 {
  cursor: pointer;
}
.dropdown-menu.show a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.catdata_name span.checkbx,
span.checkbx i,
.switch.switch-medium small,
span.import_icon i:before,
.outer_uploadsshdf,
.single_doc,
#wrapper #content-wrapper {
  position: relative;
}

thead {
  position: sticky;
  top: 0;
}

span.checkbx i,
.image_placeholder_showup_file input,
.input-group.video_add input {
  opacity: 0;
}

.cate_data.checked_div i,
.mat-tab-label-active,
ul.navbar-nav.bg-gradient-primary.sidebar.sidebar-dark.accordion.toggled
  .nav-item
  a
  span {
  opacity: 1;
}

.bottom_cate_data.delet-item .checkbx {
  display: inline;
}

.single_doc {
  margin: 0px 10px 10px 0px;
}

.search input {
  width: 250px !important;
}

a.btn.btn-primary.common-btn {
  margin: 0 5px;
  border: unset;
}

.tab_innrdv,
.dsh_crd,
.dsh_crd_bx,
.outr_fddx,
.wrpr .usr_tbl_fdx,
.nw_field.datesls,
.booking_crd_fdx,
.onlinerev_mn,
.fdx-count {
  align-items: stretch;
}

.form-control {
  height: auto;
  line-height: normal;
  color: #6e707e;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ngx-dropdown-container button .nsdicon-angle-down::before {
  top: 5px !important;
  left: 5px;
}

a.nav-link .drop {
  transform: rotate(90deg);
  transition-duration: 0.6s;
}

a.nav-link.collapsed .drop {
  transform: rotate(0deg);
}

.table_outer_main {
  min-width: 1920px;
  overflow-x: auto;
}

.child_svg img {
  width: 18px;
}

.admin_setting .mat-tab-label {
  height: auto;
  padding: 8px 10px;
  justify-content: left;
}

.single_doc,
tr.cdk-drag input {
  width: 30%;
}

.table-responsive ui-switch#Active span.switch.switch-medium {
  margin: 0 auto;
}

.right_login,
.liveTracking .tleft,
.tabbing_top_innerright .driver_details {
  width: 70%;
}

td.product_img.center .man {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

tr.cdk-drag input {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}

.right_login {
  height: 100vh;
}

.login_formss,
.admin_setting .mat-tab-body-wrapper {
  width: 79.5%;
}

.mat-tab-list .mat-tab-label,
.login_formss,
.admin_setting .mat-tab-body-wrapper {
  border-radius: 6px;
}

.admin_setting .mat-tab-body-wrapper {
  padding: 10px 30px;
}

.admin_setting_titlemain {
  p {
    color: #727272;
  }
  h2 {
    margin-bottom: 4px;
  }
}

.bigholder {
  height: 200px;
}

.dimg img,
.drier_img img,
.user_img img,
table.table.table-bordered td img {
  object-fit: cover;
}

.imgdouter-sec .ddmreli .image_placeholder img,
.withinputs .image_placeholder img {
  object-fit: contain;
}

.front_back_cont label.mat-radio-label {
  margin-left: 10px !important;
}

div#wrapper {
  min-height: calc(100vh - calc(0rem + 0px)) !important;
}

.multiselect-dropdown .dropdown-btn {
  border: 1px solid #adadad47 !important;
}

.ngx-dropdown-container button .nsdicon-angle-down::before {
  height: 5px !important;
  width: 5px !important;
}

app-no-data-found,
.list_table .list_icon {
  width: 50%;
  margin: 0 auto;
}
.dsh_map {
  height: 425px;
}

//Side bar.sidebar .nav-item .collapse .collapse-inner .collapse-item,.sidebar .nav-item .collapsing .collapse-inner .collapse-item {color:#000;}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: $primary_color !important;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaeaea !important;
}

.sidebar-dark .nav-item.active .nav-link {
  color: $text_sidebar_textcolor;
}

.sidebar-dark .nav-item.active .nav-link {
  background: linear-gradient(226deg, var(--primary-color), #000000);
  margin-left: 1px;
}

.sidebar-dark .nav-item .nav-link {
  position: relative;
}

ul.navbar-nav.lft_nvbr {
  width: 39%;
  margin: 0 auto;
}
ul.navbar-nav.ryt_nvbr {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  p {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin-right: 5px;
    white-space: nowrap;
  }
}
button.btn.btn-primary.web_btn.animate__bounce {
  padding: 5px;
  margin: 5px !important;
}
.ntf_prfle {
  justify-content: flex-start;
}
.ntf_prfle_end {
  justify-content: flex-end;
}
.sidebar-dark .nav-item.active .nav-link span {
  color: #fff;
}

.grn_btn {
  background: $grn_btn;
}

.sidebar-dark .nav-item .nav-link::after {
  color: $whitecolor;
}

.liveTracking .tleft {
  height: 540px;
}

.liveTrackingOuter {
  margin: 20px 0 0 0;
}

ul.innettabss {
  height: 40px;
}

.dimg,
.drier_img {
  width: 45px;
  height: 45px;
  border: solid 1px #e3e3e3;
}
.container-fluid.privacy-terms h4 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 22px;
  padding: 5px;
}

.listall {
  overflow-y: scroll;
  height: 500px;
}

.tright {
  padding: 10px 0 0 20px;
  width: 30%;
}
.clendr {
  position: relative;
  span.input-group-text.trigger {
    position: absolute;
    top: 3px;
    right: 0px;
    border: unset;
    background: transparent;
  }
}

.add_wh_se input#phone {
  width: 450px !important;
}

agm-map.sebm-google-map-container {
  height: 540px;
}

.main_admin_details,
.trip_fare li {
  border-bottom: 1px solid #eaeaea;
}

tr.list_thding th {
  color: #000000b3 !important;
  font-weight: 600 !important;
}
p.item_descrptn {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}
.filters_outer i.fa.fa-th,
i.fa .fa-list {
  font-size: 18px;
}

.ltg_ds_sdbr li a:hover,
tr:hover {
  background: $grey-color;
}

.order_type {
  font-size: 12px;
  color: #000000c7;
  background: #e5e8f7e8;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px;
  padding: 15px;
  font-weight: 600;
  box-shadow: 0px 0px 2px #0000002e;
  margin-right: 5px;
}

table.table .list_icon {
  width: 30%;
  margin: 0 auto;
}

.tabbing_top_heading {
  border-bottom: 1px solid #dedede;
}

.inner_content_tabbing {
  width: 49.5%;
}

.upper_details_inner .user_img figure {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #c7c7c7;
  overflow: hidden;
  margin-right: 10px;
}

.customer_details .amount {
  color: #30373f;
}

.tracking_right_detils,
.tracking_listing li {
  width: 280px;
  text-overflow: ellipsis;
}

ul.tracking_listing {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

// order card css end//
.sidebar .nav-item .nav-link,
.sidebar.toggled .bg-white .child_svg {
  padding: 15px 10px;
}

.trip_fare,
.driver_customer_details .tab-pane,
.main_hding {
  margin: 20px 0;
}

.pagination_ui,
.dashadmin_chaats,
.charts_dash {
  padding-top: 20px;
}
.admin_log figure {
  width: 100px;
  height: 80px;
}

.No_data h3 {
  text-align: center;
  font-size: 14px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

#wrapper #content-wrapper {
  width: 87%;
  padding: 100px 30px 30px 30px;
  background: #f9f9f9;
  margin-left: auto;
}
.ag_tabs .mat-tab-body.mat-tab-body-active {
  overflow: visible !important;
}
.ag_tabs .mat-tab-body.mat-tab-body {
  overflow: visible !important;
}
.mat-tab-body-content {
  overflow: inherit !important;
}
.mat-tab-body-wrapper {
  overflow: visible !important;
}

.copyright {
  position: relative;
  margin-top: 15px;
  color: #202020;
  font-size: 20px;
}

.table-body,
.cat_ui .crd_padd,
.outer_uploadsshdf,
.drier_img,
.dimg,
.liveTracking .tleft,
ul.tracking_listing,
.card_flex .hvrcursor,
.upper_details_inner.user_img,
.tracking_right_detils,
.tracking_listing li {
  overflow: hidden;
}

.filterss,
.admin_setting_title,
.feature_card,
.admin_setting_titlemain {
  margin-bottom: 20px;
}

.catbtn_ui.ctr_imp_bttns span.import_icon i:before {
  top: unset;
}

.clr-blk {
  color: black;
}

.showProductSearch {
  opacity: 1 !important;
  top: 12px;
}

.cat_ui .crd_padd {
  width: 49%;
}

.str_cate_dv .category_tabs .category_layout.ctge_lst {
  padding: 10px !important;
}

.ctge_lst .catdata_name {
  width: 80%;
}

.ctge_lst p span.z_ind_m {
  height: 20px !important;
  width: 20px !important;
  align-items: center;
  justify-content: space-around;
}

.ctge_lst p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.cate_data.checked_div .ctge_lst p {
  color: #2f98d4;
}

.cate_data.checked_div .ctge_lst p span.z_ind_m {
  display: flex !important;
}

.cate_data.checked_div .ctge_lst p span.z_ind_m i {
  top: unset !important;
}

.allclc-btn {
  margin: 20px 0px;
  padding-right: 10px;
}

.checked_div {
  color: white !important;
}

.movires {
  display: none;
}

.fixed-delete1 {
  font-size: 2em;
  font-weight: bold;
  color: white;
  z-index: 999;
  width: 66%;
  margin-left: 12px;
}

.fixed-delete-cross1 {
  font-size: 2em;
  font-weight: bold;
  background-color: red;
  color: white;
  z-index: 999;
}

.bottom_cate_data {
  height: 380px;
  overflow-y: auto;
  margin-top: 14px;
  overflow-x: hidden;
}

.zone_panel .cal-md-6 {
  width: 48%;
}

.zone_panel {
  margin-right: 0;
}

.dropdown-menu.dropdown-mes.dropdown-menu-right.shadow.animated--grow-in {
  width: 300px;
  margin-top: 8px !important;
}

.catdata_name span.checkbx {
  padding: 1px 5px;
  .z_ind_m {
    z-index: 99;
  }
}

span.checkbx i {
  font-size: 9px;
  top: -1px;
}

.sidebar-toggled div#content-wrapper {
  width: 100% !important;
  padding-left: 90px !important;
}

.sidebar-toggled .topbar {
  width: 100%;
  padding-left: 90px;
  z-index: 9;
}

.profilein_image {
  padding: 15px 10px;
}

.addbtn_action {
  width: 14%;
}

.card-body {
  flex: 0;
  min-height: 0;
}

.mrcrd_hd h1 {
  font-size: 22px;
  transform: translateY(4px);
  color: #060606;
}
.err_hndle {
  text-align: center;
}

.col-card {
  height: 50px;
  width: 50px;
  svg {
    width: 30px;
    height: 30px;
    animation: swing ease-in-out 1s infinite alternate;
  }
  svg path,
  circle {
    fill: #ffffffb8;
  }
}

.card_flex .hvrcursor {
  width: 24%;
  margin: 3px 1px 5px 8px;
}

.sidebar-brand-icon {
  background: #fff;
  padding: 10px 20px;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.image_placeholder_showup_file {
  top: 0;
}

.mat-expansion-panel {
  background: rgba(0, 0, 0, 0.04);
}

.driver_customer_details ul.nav.nav-tabs {
  background: #d8d8d86b;
}

.dftlp img {
  width: 50px;
}

.dftlp span {
  font-size: 12px;
  margin-top: 8px;
  font-weight: 400;
  color: #999393;
}

.outer_uploadsshdf label {
  padding: 4px;
  margin-bottom: 0 !important;
}

.drpdwn_blc .ngx-dropdown-button {
  left: 0;
  z-index: 9;
  top: 4px;
  width: 16% !important;
  border: 0px !important;
  background-color: transparent !important;
}

/*#cdk-overlay-1 {
  max-width: 50vw !important;
}*/
#cdk-overlay-0,
#cdk-overlay-1,
#cdk-overlay-3,
#cdk-overlay-4,
#cdk-overlay-5,
#cdk-overlay-6,
#cdk-overlay-7,
div#cdk-overlay-8,
#cdk-overlay-9,
#cdk-overlay-10,
#cdk-overlay-11,
#cdk-overlay-12,
#cdk-overlay-15,
#cdk-overlay-50 {
  max-width: 40% !important;
  pointer-events: auto;
}

// new css//
.admin-table .table_box,
.trip_fare li {
  padding: 5px 0px;
}

.up_file.video-up img {
  width: 140px;
}

.table_id {
  padding: 30px;
}

.table_inner.width_increase {
  width: 300px;
}

.zone_panel .selector_div {
  width: 125px;
}

.table_inner {
  width: 166px;
}

.ltg_ds_sdbr::-webkit-scrollbar {
  max-height: 10px;
  max-width: 3px;
  width: auto;
}
figure.profile_image img {
  border-radius: 100px;
  margin-left: 10px;
}

.feature_top figure {
  height: 26px;
}

.feature_card {
  min-height: 190px;
  position: relative;
}

.chrome-picker {
  width: 99% !important;
  box-shadow: none !important;
}

.saturation {
  padding-bottom: 25% !important;
}

.top {
  padding-bottom: 10px;
}

li.nav-item a img {
  height: 20px;
}

.tag_left {
  width: 40%;
}

.tag_right {
  width: 60%;
}
.navbar-expand .navbar-nav .nav-link.prfl_bx,
.prfl_bx {
  .profile_image {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  p {
    font-size: 12px;
    font-weight: 500;
    b {
      font-size: 12px;
    }
  }
}

.navbar-expand .navbar-nav .nav-link p b {
  color: #000000;
  font-weight: 600;
}

.navbar-expand .navbar-nav .nav-link p {
  font-size: 14px;
}

.navbar-expand .navbar-nav .nav-link span {
  display: none;
}

.rounded-circle {
  border-radius: 50% !important;
}

// side bar css //
.scrollbar {
  overflow-y: hidden !important;
}

.bg-gradient-primary {
  height: 100% !important;
  width: 13.5%;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: #fff;
  transition: all 0.2s ease;
  box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 4%);
  z-index: 100;
}

.navbar-nav {
  -webkit-box-orient: vertical;
  padding-left: 0;
  list-style: none;
}

.totl_cmrv {
  font-size: 16px !important;
  font-weight: 500;
  color: $para-clr;
  margin-left: 10px;
}

.mat_chart mat-select#mat-select-0 span {
  color: $para-clr;
}
.logo-settings {
  padding: 8px;
  background-color: green;
  margin: 0px !important;
  height: 63px;
  line-height: 53px;
}

.ltg_ds_sdbr {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100% !important;
}

.sidebar .nav-item img {
  filter: invert(0.5);
}
.nav-link:hover img {
  animation: jiggle ease-in-out 400ms infinite;
}
@keyframes jiggle {
  0% {
    transform: none;
  }
  25% {
    transform: rotateZ(5deg);
  }

  75% {
    transform: rotateZ(-5deg);
  }
  100% {
    transform: none;
  }
}

.child_svg:hover img {
  animation: jiggle ease-in-out 400ms infinite;
}

.clor {
  background-color: var(--primary-color) !important;
}
.sidebar .nav-item.active img {
  /* filter: invert(30%) sepia(45%) saturate(5466%) hue-rotate(228deg) brightness(88%) contrast(97%); */
  filter: brightness(2);
}

.zone_panel .selector_div mat-select {
  padding: 8px 5px 0px 30px;
  border: unset !important;
}

.mat-select-arrow,
.mat-select-value {
  color: #b8b8b8;
}

.navbar-expand .navbar-nav .nav-link i {
  font-size: 30px;
}

.navbar-expand .navbar-nav .nav-link {
  position: relative;
}

.navbar-expand .navbar-nav .nav-link .badge {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #5ecfff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #fff !important;
  top: -10px;
  right: -10px;
  border: 2px solid #fff;
  font-size: 12px;
}
.choose_wrp .modal-dialog {
  margin: 0 !important;
}

// sidebar css end //
// top bar strt//
.topbar {
  position: fixed !important;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 99;
  height: 65px;
  box-shadow: 0 0 5px #00000012;
}

.mat_chart {
  width: 150px;
  border: 1px solid #dedede75;
  margin: 10px;
  padding: 6px;
  margin-left: auto;
  border-radius: 5px;
  position: relative;
  bottom: 50px;
}

figure.mini_sidebar_btn {
  width: 18px;
  height: 28px;
  filter: invert(0.5);
}

.table_driver,
.drvr_tbl {
  min-width: 1040px;
}

.order_dtails ul li.blue_block {
  border: solid 1px #c7e9ff;
}

li.blue_cancle {
  border: 1px solid #ffd3d3;
}

li.blue_complete {
  border: 1px solid #caffb2;
}

li.all_block {
  border: 1px solid #0000001a;
}

.order_dtails ul li {
  width: 24%;
  padding: 5px 5px;
}

li.all_block .order_inner_detail {
  padding: 0 10px;
}

li.blue_block .order_inner_detail h4 {
  color: #0171bd;
}

li.blue_cancle .order_inner_detail h4 {
  color: #ff4d4d;
}

li.blue_complete .order_inner_detail h4 {
  color: #309b00;
}

.table_order_dtail {
  width: 492px;
}

.table_right {
  border: solid 1px #ddd;
  border-radius: 4px;
  height: auto;
  min-height: 116px;
}
.table_order_dtail {
  width: 492px;
}
.switch.checked {
  background: transparent;
}
.switch.switch-medium {
  height: 22px;
  width: 50px;
  background: #f6eeff33;
  margin: 0 auto;
}
.switch.switch-medium small {
  width: 18px;
  height: 18px;
  right: calc(100% - 20px);
}

span.switch.switch-medium.checked small {
  right: calc(100% - 48px);
}
.bigholderhor {
  height: 265px;
}
.outer_uploadsshdf p {
  padding: 4px;
  color: #828282;
  margin-bottom: 0 !important;
}
.image_placeholder {
  background: transparent;
}
.image_placeholder_showup_file {
  top: 0;
  z-index: 0;
}
.invalid-feedback {
  display: block !important;
  margin-top: 0.14rem;
  font-size: 95%;
  color: #f55353;
}
span.add_mrebx_delt {
  color: #ff00008a;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}
.input-group .new-select {
  border: unset;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}
.outer_uploadsshdf.bigpro {
  width: 150px;
  height: 150px;
}
.mat-tab-list .mat-tab-label {
  min-width: 80px !important;
  font-size: 14px;
}
label.switch {
  height: 22px !important;
}
.sidebar.toggled .bg-white {
  padding: 0px !important;
  margin: 0 4px 0 5px !important;
}
.card_tg_list {
  .tg-list {
    text-align: center;
    display: flex;
    align-items: center;
  }
  .tg-list-item {
    margin: 0 2em;
  }
  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .tgl::-moz-selection,
  .tgl:after::-moz-selection,
  .tgl:before::-moz-selection,
  .tgl *::-moz-selection,
  .tgl *:after::-moz-selection,
  .tgl *:before::-moz-selection,
  .tgl + .tgl-btn::-moz-selection {
    background: none;
  }
  .tgl::selection,
  .tgl:after::selection,
  .tgl:before::selection,
  .tgl *::selection,
  .tgl *:after::selection,
  .tgl *:before::selection,
  .tgl + .tgl-btn::selection {
    background: none;
  }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .tgl + .tgl-btn:after,
  .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .tgl + .tgl-btn:after {
    left: 0;
  }
  .tgl + .tgl-btn:before {
    display: none;
  }
  .tgl:checked + .tgl-btn:after {
    left: 50%;
  }
  .tgl-light + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
  }
  .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
  }
  .tgl-light:checked + .tgl-btn {
    background: #9fd6ae;
  }
  .tgl-ios + .tgl-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #e8eae9;
  }
  .tgl-ios + .tgl-btn:after {
    border-radius: 2em;
    background: #fbfbfb;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
  }
  .tgl-ios + .tgl-btn:hover:after {
    will-change: padding;
  }
  .tgl-ios + .tgl-btn:active {
    box-shadow: inset 0 0 0 2em #e8eae9;
  }
  .tgl-ios + .tgl-btn:active:after {
    padding-right: 0.8em;
  }
  .tgl-ios:checked + .tgl-btn {
    background: #86d993;
  }
  .tgl-ios:checked + .tgl-btn:active {
    box-shadow: none;
  }
  .tgl-ios:checked + .tgl-btn:active:after {
    margin-left: -0.8em;
  }
  .tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: "Poppins", sans-serif;
    background: #888;
  }

  .tgl-skewed + .tgl-btn:after,
  .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
  }
  .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
  }
  .tgl-skewed + .tgl-btn:active {
    background: #888;
  }
  .tgl-skewed + .tgl-btn:active:before {
    left: -10%;
  }
  .tgl-skewed:checked + .tgl-btn {
    background: #86d993;
  }
  .tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
  }
  .tgl-skewed:checked + .tgl-btn:after {
    left: 0;
  }
  .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
  }
  .tgl-flat + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    background: #fff;
    border: 4px solid #f2f2f2;
    border-radius: 2em;
  }
  .tgl-flat + .tgl-btn:after {
    transition: all 0.2s ease;
    background: #f2f2f2;
    content: "";
    border-radius: 1em;
  }
  .tgl-flat:checked + .tgl-btn {
    border: 4px solid #7fc6a6;
  }
  .tgl-flat:checked + .tgl-btn:after {
    left: 50%;
    background: #7fc6a6;
  }
  .tgl-flip + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: "Poppins", sans-serif;
    perspective: 100px;
  }

  .tgl-flip + .tgl-btn:after,
  .tgl-flip + .tgl-btn:before {
    display: inline-block;
    transition: all 0.4s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 4px;
  }

  .tgl-flip + .tgl-btn:after {
    content: attr(data-tg-on);
    background: #02c66f;
    transform: rotateY(-180deg);
  }
  .tgl-flip + .tgl-btn:before {
    background: #ff3a19;
    content: attr(data-tg-off);
  }
  .tgl-flip + .tgl-btn:active:before {
    transform: rotateY(-20deg);
  }
  .tgl-flip:checked + .tgl-btn:before {
    transform: rotateY(180deg);
  }
  .tgl-flip:checked + .tgl-btn:after {
    transform: rotateY(0);
    left: 0;
    background: #7fc6a6;
  }
  .tgl-flip:checked + .tgl-btn:active:after {
    transform: rotateY(20deg);
  }
}

.child_svg .active span {
  color: var(--primary-color);
  font-weight: 600;
}

.total_bill h4 {
  font-size: 20px !important;
}

// new table css start
.dtl_data {
  display: flex;
  align-items: center;
  width: 63%;
  margin: 0 auto;
  .outr_Dp {
    background: #00649e14;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    figure {
      width: 30px;
      height: 30px;
      overflow: hidden;
    }
  }
  h6 {
    font-size: 14px;
    margin-left: 10px;
    span {
      font-weight: 400;
      color: #bbb6b6;
      font-size: 13px;
    }
  }
}

.tbl_new_wrp tr th {
  padding: 15px 12px;
  text-align: center;
}

.tbl_new_wrp tr td {
  padding: 8px 12px;
  text-align: center;
}

.tbl_new_wrp th {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.outr_chkbx button {
  background: #8bdd5973;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #008000f0;
  font-weight: 500;
  width: 60%;
  span {
    margin-right: 5px;
    font-size: 16px;
  }
}

.tbl_new_wrp td {
  font-size: 13px;
  font-weight: 500;
  color: #767676;
}

.tbl_new_wrp .card {
  border-radius: 15px;
}

.cdk-overlay-container {
  z-index: 10000;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  display: none;
}

.drv_odrdtl_otr {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 10px;
}

.drp_ecl mat-form-field .mat-form-field-wrapper {
  padding: 0px;
}

.pac-container.pac-logo.hdpi {
  z-index: 99999 !important;
  background: #fff !important;
}

// new table css end
.dsh_crd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #80808014;
  padding: 20px;
  margin: 80px 0px;
  border-radius: 10px;

  mat-tab-header {
    .mat-tab-list {
      .mat-tab-label {
        background: var(--primary-color);
        height: auto;
        margin: 20px 10px 0px 0px;
        padding: 5px;
        color: #fff;
        font-weight: 400 !important;
        opacity: 1;
      }
      .mat-tab-label-active {
        color: #fff;
        font-weight: 500 !important;
      }
    }
    mat-ink-bar {
      display: none;
    }
  }
  &:first-child {
    margin: 0px 0px 40px;
  }
}

.dsh_bx .dsh_fig {
  background: #ff54111c;
  width: 45px;
  height: 45px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dsh_crd_lft,
.dsh_crd_ryt {
  width: 49%;
}

.dsh_bx {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  box-shadow: 0px 0px 3px 2px #00000008;
}

.nw_input_fld {
  background: #c3c3c32e;
  border-radius: 18px;
  border: 1px solid #c3c3c324 !important;
}

.cnt_img {
  padding: 15px 150px 15px 30px;
}

.dsh_bx p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.dsh_bx h3 {
  font-weight: 500;
  color: #202020;
  font-size: 16px;
  line-height: 34px;
}

.dsh_bx button {
  margin-top: 10px;
  border-radius: 5px;
}

.crle_prgres svg.ng-star-inserted {
  width: 145px;
  height: 145px;
}
tspan.ng-star-inserted {
  font-size: 24px;
}

.intl-tel-input .country-list {
  width: 100%;
}
.mobile_res button {
  display: none;
}

.dsh_crd_bx {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 260px;
  overflow-y: scroll;
  padding-right: 6px;
  gap: 20px;
}

.dsh_crd_bx .dsh_bx {
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dsh_crd_bx .dsh_bx .dsh_cnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dsh_crd_bx .dsh_bx .dsh_cnt h2 {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px !important;
  margin-top: 14px !important;
}

.add_ussr {
  ngx-intl-tel-input#phone,
  .iti.iti--allow-dropdown,
  input#phone,
  ngx-intl-tel-input,
  input {
    width: 100%;
  }
}

.rsnn_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h6 {
    color: #202020;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }
  .right_rsn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .button_sub {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.dsh_crd_bx .dsh_bx .dsh_cnt p {
  display: flex;
  justify-content: flex-start;
  color: #9f9f9f;
  font-size: 15px;
  font-weight: 300;
}

.dsh_btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dsh_img {
  width: 100%;
  .demo-chart {
    height: 430px;
  }
}
.dsh_btm .dsh_cnt {
  width: 49%;
  position: absolute;
  top: 10px;
  right: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    margin: 0;
  }
}

.gm-style-mtc {
  margin-left: 10px;
}

.dsh_btm .dsh_cnt h2 {
  display: flex;
  align-items: center;
  color: #202020;
  font-size: 20px;
  font-weight: 700;
  gap: 10px;
}

.dsh_btm .dsh_cnt h2 figure {
  margin-right: 5px;
}

.dsh_bx .dsh_btm .dsh_cnt span {
  color: #a5a5a5;
  font-weight: 600;
  display: inline-flex;
}

.dsh_bx .dsh_tp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.dsh_bx .dsh_tp button {
  margin-top: 0px !important;
  background: transparent;
  border: 1px solid var(--primary-color) !important;
  font-size: 18px;
  color: #6418c3;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dsh_bx .dsh_tp button img {
  margin-right: 10px;
}

.dsh_bx.dsh_tbl {
  padding: 0px;
  overflow: hidden;
}

.dsh_bx.dsh_tbl thead tr {
  background: #d18f4e;
}

.dsh_bx.dsh_tbl thead tr th {
  font-size: 16px;
  color: #202020;
  font-weight: 400;
}
.dsh_tbl .table-responsive tr {
  border-bottom: 1px solid #ebebeb;
}

.dsh_bx.dsh_tbl tbody tr td {
  font-size: 16px;
  color: #202020;
  background: #fff;
  border: 0;
}

.web_wrap {
  .wb_fdx {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;
  }
  .add {
    margin-bottom: 10px;
  }
}

p.status {
  white-space: nowrap;
  background: #c6edff;
  color: #00adff;
  margin-top: 0px;
  padding: 5px 8px;
  border-radius: 8px;
  font-weight: normal;
}

.drp_ecl mat-form-field {
  border: 1px solid #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.dsh_fig {
  max-width: 75%;
}

/*new rocky css*/
.filter-prd h3 {
  font-size: 18px;
}
.add_ussr .bigpro {
  margin: auto;
}

.new_usr_wrap {
  .user_fdx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .grd_btn {
        background: var(--primary-color);
        width: 100%;

        figure {
          width: 15px;
          margin: 0;
        }
      }
      .new_usr_btn {
        width: 100%;
        button {
          font-size: 14px;
          border-radius: 6px;
          width: 100%;
          background: var(--primary-color);
          line-height: normal;
          span {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.new_usr_wrap .user_fdx .right .new_usr_btnn button {
  font-size: 14px;
  padding: 11px;
  border-radius: 6px;
  width: 100%;
  background: var(--primary-color);
  line-height: normal;
}

.outer_usr {
  width: 24%;
  background: #fff;
  padding: 8px 10px;
  border-radius: 10px;
  margin: 0px 10px 10px 0px;
  position: relative;
  height: 160px;
  .usr_innr_fdx {
    display: flex;
  }
  .svg_fdx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .usr_innr_fdx .left_usr_dtl {
    width: 23%;
  }
  .usr_innr_fdx .right_usr_dtl {
    width: 75%;
    padding-left: 10px;
  }

  .usr_innr_fdx .right_usr_dtl h3 {
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .usr_innr_fdx .right_usr_dtl .svg_fdx figure {
    margin-right: 10px !important;
  }
  .right_usr_dtl li {
    padding: 2px;
  }
  .right_usr_dtl .svg_fdx p {
    color: #a5a5a5;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    white-space: nowrap;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .right_usr_dtl .svg_fdx p:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
  .top_btn {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 10px;
  }
  .outr_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.user_fdx .srch_cn input {
  padding: 20px 35px 20px 37px;
  width: 330px;
  border-radius: 5px;
  border: 1px solid #00000085 !important;
}
.outr_fddx {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px 0px;
}
.outr_img {
  width: 40px;
  height: 40px;
  position: relative;
  ::after {
    content: "";
    background: url("../img/online_sign.svg");
    width: 100%;
    height: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50%;
    position: absolute;
    top: 20px;
    right: -10px;
  }
}
.left_usr_dtl figure {
  width: 60px;
  height: 60px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 50px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.block_btn {
  background: #ffd8d8;
  color: #38e25d;
  padding: 5px;
  border-radius: 5px;
}

.mat-date-range-input-container {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px !important;
}
.mat-form-field-flex {
  align-items: center !important;
}
.mat-date-range-input-container .cmn_frm .input-group input {
  background: none !important;
}
/*new rocky css end*/
/**********************************************
**********************************************
************30/03/22 New Design***************
**********************************************
**********************************************
**********************************************/

.table {
  background: #f9f9f9;
}
.table.border_spacing {
  border-spacing: 0px 10px;
  border-collapse: separate;
}
.border_spacing thead th {
  vertical-align: middle;
}
.border_spacing thead tr {
  height: 60px;
  background: #c3c3c324;
}
.border_spacing td {
  border-top: 0;
}
.table.border_spacing th {
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: #000000;
}
.border_spacing tbody td {
  vertical-align: middle;
}
.border_spacing tbody td {
  vertical-align: middle;
  color: #000000;
  font-size: 14px;
  line-height: normal;
}
.table.border_spacing thead th:first-child {
  border-top-left-radius: 20px;
}
.table.border_spacing thead th:last-child {
  border-top-right-radius: 20px;
}
.border_spacing tr {
  vertical-align: middle;
  background: #fff;
}
.border_spacing tbody td {
  padding: 0;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}
.tbl_act_btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 5px 15px;
  border-radius: 7px;
  color: #38e25d;
  background: #d8ffe0;
  width: 100px;
}
.tbl_inact_btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 5px 15px;
  border-radius: 14px;
  color: #f31515;
  background: #ffd8d8;
  width: 100px;
}
.nw_cmmn_btn {
  padding: 5px 20px;
  background: var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  border-color: var(--primary-color);
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
  height: auto;
}
.nw_cmmn_btn img {
  margin-right: 10px;
}
.iti--allow-dropdown input,
.iti--allow-dropdown input[type="tel"] {
  margin-left: 0 !important;
  height: 35px !important;
  font-size: 12px;
}

.paymt_hdng {
  margin: 10px 0px 20px;
}

.srch_cn input {
  // padding: 19px;
  padding-left: 35px;
}
.search .srch_cn input {
  width: 350px !important;
  padding: 20px;
  padding-left: 63px;
  border: 1px solid #00000085 !important;
  border-radius: 6px;
}
.srch_cn {
  position: relative;
  display: flex;
  align-items: center;
}
.srch_cn .fa-search {
  position: absolute;
  left: 11px;
  color: var(--primary-color);
  font-size: 18px;
  top: 12px;
}
.user_title h6 {
  color: #202020;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
.show_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pagintn_lst i {
  display: flex;
  border: 1px solid #c2c2c2;
  border-radius: 14px;
  font-size: 22px;
  padding: 8px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}
.pagintn_lst ul {
  display: flex;
  border: 1px solid #c2c2c2;
  border-radius: 14px;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-align: center;
  margin: 0px 10px;
}
.pagintn_lst ul li {
  width: 40px;
}
.pagintn_lst ul .active {
  background: var(--primary-color);
  padding: 8px;
  border-radius: 14px;
  color: #fff;
}
.pagintn_lst {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.show_txt p span {
  font-weight: bold;
}

.show_txt p {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.border_pd tbody td {
  padding: 5px;
}

.wrpr {
  margin: 30px 0px;
  .table_view_outr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .right_usr ul {
      display: flex;
      margin-bottom: 7px;
    }
    .usr_tbl_fdx {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 49%;
    }

    .elipises .drpdwn_action.dropdown-menu.h-auto.show {
      transform: rotate(-90deg) !important;
      top: 45px !important;
      left: -26px !important;
      min-width: 30px !important;
      padding: 0 !important;
    }

    .left_usr img {
      width: 90px;
    }

    .right_usr {
      width: 91%;
      padding-left: 5px;
    }

    .usr_tbl_fdx {
      border-radius: 14px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      padding: 15px;
      margin-bottom: 20px;
      position: relative;
    }
    .right_usr ul li h6 {
      font-size: 14px;
      line-height: 26px;
      font-weight: 500;
    }
    .inr_Flx {
      display: flex;
      align-items: center;
      figure {
        padding: 0px 8px;
      }
    }
    .usr_lst_cls li {
      border-radius: 7px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      padding: 8px;
      text-align: center;
      margin-right: 10px;
    }
    .right_usr .usr_lst_cls {
      margin-bottom: 10px;
    }
    .usr_lst_cls li:last-child {
      margin-right: 0;
    }
    .inr_Flx p {
      color: #a5a5a5;
      font-weight: 400;
      font-size: 12px;
    }
    ul.usr_lst_cls li.accepted h5 {
      color: #0187d4;
      font-size: 13px !important;
      font-weight: 500;
    }

    ul.usr_lst_cls li.Cancelled h5 {
      font-size: 13px !important;
      color: #ff4d53;
    }
    ul.usr_lst_cls li.Completed h5 {
      font-size: 13px !important;
      color: #6c9b00;
    }
    li.memberShipPlan h5 {
      font-size: 13px !important;
      color: #38e25d;
    }

    .right_usr ul.usr_lst_cls li h5 span {
      color: #000;
      padding-top: 5px;
    }

    p.usr_email {
      margin: 2px 0px 5px !important;
      color: #a5a5a5;
      font-size: 14px;
      line-height: normal;
      font-weight: 500;
    }
  }
}

// pagination UI
li.pagination-previous {
  border: 1px solid #c2c2c2;
  border-radius: 7px;
  margin-right: 10px;
  text-align: center;
}

li.pagination-next {
  border: 1px solid #c2c2c2;
  border-radius: 7px;
  margin-left: 10px;
  text-align: center;
}

ul.ngx-pagination li {
  border-radius: 6px;
}

.ngx-pagination .current {
  padding: 16px 18px !important;
  color: #fefefe;
  width: 35px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "<" !important;
  display: inline-block;
  margin: 0 !important;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 18px;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: ">" !important;
  margin: 0 !important;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 18px;
}
.ngx-pagination .disabled {
  margin-right: 10px;
  padding: 1px 10px !important;
}
.ngx-pagination a {
  padding: 1px 10px !important;
}
ul.ngx-pagination {
  display: flex;
  align-items: center;
}
// pagination end

.table_view_outr .left_usr figure {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 50px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.elipises {
  position: absolute;
  bottom: 15px;
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 18px;
  right: 8px;
}
.right_usr_dtl ul {
  margin-bottom: 0;
}
.elipises {
  .dropdown-toggle::after {
    display: none !important;
  }
}
.table_view_outr .usr_tbl_fdx .top_btn {
  position: absolute;
  right: 15px;
}
.tbl_pnd_btn {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 7px;
  color: #ffab2d;
  background: #ffebcc;
}

.border_pd tbody td img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

figure.man img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

span.import_icon i:before {
  background: transparent;

  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #fff;
}

.btn_grp button i.fa.fa-list {
  background: transparent;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #fff;
}

.filters_outer i.fa.fa-th {
  padding: 6px 2px;
  background: var(--primary-color);
  border-radius: 7px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  color: #fff;
}
.input-group mat-form-field {
  width: 100%;
}

.cmn_frm .input-group input,
.cmn_frm .input-group mat-select,
.cmn_frm .input-group textarea.form-control,
.cmn_frm .input-group input,
.clendr .mat-form-field {
  border-radius: 5px !important;
  background: #c3c3c31f !important;
  border: 1px solid #0000005e !important;
}

.nw_field input,
.nw_field select,
.nw_field .multiselect-dropdown {
  background: whitesmoke;
  border-radius: 5px !important;
  border: 1px solid #0000005e !important;
}

.nw_field.datesls {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.nw_field .input-group-append {
  position: absolute;
  right: 3px;
  top: 3px;
}

.nw_field .multiselect-dropdown {
  background: whitesmoke;
  border-radius: 14px !important;
  border: 1px solid #0000005e !important;
}

.nw_field mat-select {
  background: whitesmoke;
  border: 1px solid #0000005e !important;
}
.nw_field ng-multiselect-dropdown .dropdown-btn {
  background: whitesmoke;
  border-radius: 14px !important;
  border: 1px solid #0000005e !important;
}

.nw_field .mat-select-value {
  line-height: 21px;
}

.privacy-terms p {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  font-weight: 400;
  font-family: sans-serif;
  padding: 5px;
  text-align: justify;
}

.headng {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #0000005e;
}
.cmn_frm h3 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  padding: 15px 0px;
}

.cmn_frm .input-group textarea.form-control {
  height: 50px;
}

.cmn_frm .input-group input:focus-visible,
.cmn_frm .input-group mat-select:focus-visible,
.cmn_frm .input-group textarea.form-control:focus-visible {
  border: 2px solid var(--primary-color) !important;
}

.cmn_frm .form-group .input-group-text {
  background: whitesmoke;
  border: 0;
  border-radius: 0px 10px 10px 0px !important;
}

.cmn_frm .form-group .input-group-text img {
  width: 16px;
}
/*bookings detail card css strt*/
.bookings_outr {
  border: 2px solid #38e25d;
  width: 49%;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  position: relative;
}

body.sidebar-toggled button#sidebarToggleTop {
  position: absolute;
  left: 5%;
}

/*****01/04/22***********/

.frm_cmn_dsn .input-group input {
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  color: #464646;
  font-size: 12px;
}

.frm_cmn_dsn .input-group input::placeholder {
  color: #464646 !important;
}

.frm_cmn_dsn .input-group input::-webkit-input-placeholder {
  color: #464646;
}

.igm_reqd {
  display: none;
}
// .img_uld_fld .image_placeholder_showup_file {
//   background: #fff;
// }
.btn_vcl .nw_cmmn_btn {
  width: 100%;
  border-radius: 5px;
  padding: 13px 30px;
  font-size: 16px;
  line-height: 24px;
}

.card.shadow.pad10.crd_bx_mn {
  border: dashed 2px !important;
  border-color: #ccc !important;
  border-radius: 14px;
  padding-top: 30px;
}

.srch_cn figure.d_Cross {
  width: 13px;
  height: 24px;
  position: absolute;
  right: 18px;
}
.btn.btn-primary {
  position: relative;
  z-index: 1;
  border: unset;
}

.sidebar-dark .nav-item.active .nav-link {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    top: 0;
    transform: translateX(100%);
    width: 49%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    animation: slide 2s infinite;
    background: linear-gradient(
      to right,
      rgba(64, 235, 241, 0) 0%,
      rgb(255 254 253/56%) 50%,
      rgb(255 254 253/28%) 99%,
      rgba(64, 235, 241, 0) 100%
    );
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slides {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.show_pgnd {
  margin-top: 20px;
  justify-content: end;
}

.bookings_outr .booking_fdx {
  display: flex;
  justify-content: space-between;
}

.cntr .ttop {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tym_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bookings_outr .booking_fdx {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  padding: 10px 0px 10px 0px;
}
.bookings_outr .cntr {
  border-bottom: 1px solid #dedede;
  padding: 10px 0px;
}
.bookings_outr .btm {
  padding: 5px 0px 5px 0px;
}
.tym_outr p {
  font-size: 15px;
  color: #000;
  font-weight: 400;
  span {
    color: #202020;
    font-weight: 700;
    font-size: 14px;
  }
}
.cntr .customr_tp {
  margin-bottom: 10px;
}
.cntr .ttop p {
  color: #202020;
  font-size: 14px;
}
.cntr .ttop h5,
.cntr .ttop span {
  font-weight: 600;
  font-size: 14px;
  margin: 0px 3px;
}
.bookings_outr .btm .tym_outr {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  width: 48%;
}
.bookings_outr .booking_fdx p {
  color: #202020;
  font-size: 17px;
}
.bookings_outr .booking_fdx span {
  color: #202020;
  font-size: 14px;
  font-weight: 700;
}
.bookings_outr .fare_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.bookings_outr .fare_outr span {
  font-size: 11px;
  font-weight: 400;
  color: #6f6f6f;
}
.bookings_outr .fare_outr p {
  font-size: 14px;
  color: #202020;
  font-weight: 600;
}
.fare_outr p span {
  font-size: 14px !important;
  color: #38e25d !important;
  font-weight: 600 !important;
}
.paid_btn button {
  background: #d8ffe0;
  color: #38e25d;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 5px 15px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 10px;
}
.ordr_side.active p {
  color: #fff !important;
  margin-right: 20px;
}
.paid_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.booking_crd_fdx {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 20px;
}

/*bookings detail card css end*/
.calend_mn {
  width: 100%;
  text-align: right;
  .calendr {
    display: inline-block;
  }
  .mat-form-field-flex {
    flex-direction: row-reverse;
    background: linear-gradient(260deg, #6418c3, #ffcff2);
    border-radius: 14px !important;
    align-items: center;
  }
  .mat-form-field-infix {
    padding: 10px 3px;
    background: transparent;
    label {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 27px;
    }
  }
  .mat-datepicker-toggle svg {
    fill: #fff;
  }
  input::placeholder {
    color: #fff !important;
  }
  input::-webkit-input-placeholder {
    color: #fff;
  }
  .mat-date-range-input-separator {
    color: #fff;
  }
  .mat-date-range-input {
    color: #fff;
  }
}

.dsh_tbl .table-responsive {
  height: 537px;
  overflow: scroll;
}
.dsh_crd_bx.my-4.ng-star-inserted {
  max-height: 300px;
}
.dsh_tbl .table-responsive::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.dsh_crd_bx.mt-4.ng-star-inserted {
  max-height: 300px;
}
.fx-wdth {
  width: 550px !important;
}

/*manage sales report scss strt*/
.onlinerev_outr {
  width: 49%;
  background: #fff;
  padding: 10px;
  .onrev_fdx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .sve_rep button {
    border: 1px solid var(--primary-color) !important;
    background: #fff;
    border-radius: 7px;
    padding: 6px 15px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: bold;
  }
  .rev_closed {
    width: 46%;
    margin: auto;
    background: #fff;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    padding: 18px;
    figure {
      margin-left: 10px;
    }
  }
  .rev_closed h3 {
    font-size: 22px;
    color: #000;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .rev_closed p {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  .onrev_fdx h2 {
    font-size: 24px;
    font-weight: 500;
    color: #202020;
  }
}

.multiselect-dropdown span {
  color: rgba(0, 0, 0, 0.42);
  font-weight: 400;
  font-size: 15px;
}

.green-bg,
button.green-bg {
  background: #d8ffe0 !important;
  color: #38e25d;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  padding: 3px 8px;
  border-radius: 4px;
}
.elipises i.fa.fa-ellipsis-h {
  font-size: 14px;
}

button.red_bg {
  background: #ffd8d8 !important;
  color: #f31515;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 7px;
}
.nw_field .dropdown-list {
  box-shadow: unset;
  border-radius: 7px;
  border: 1px solid #dedede;
}

span.red-str {
  color: #ff00009c;
  margin: 0px 0px 0px 4px;
}

.card-hesaderpy {
  gap: 6px !important;
}
.col-lg-4.user_filter {
  padding: 0px;
  gap: 6px;
}
.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 35px;
  height: 40px !important;
  flex-shrink: 0;
  line-height: 35px !important;
  border-radius: 50%;
}
.dropdown-item-rgt p {
  cursor: pointer;
}
.dropdown-item-rgt p:hover {
  background-color: #e9ecef;
}

.dropdown {
  position: relative;
}
ngx-intl-tel-input.ng-dirty.ng-touched.ng-invalid {
  width: 100% !important;
}
ngx-intl-tel-input.ng-dirty.ng-touched.ng-valid {
  width: 100% !important;
}

.btn_grp.drx_btn figure {
  margin: 0px;
  width: 14px;
}

.flix {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
/*manage sales report scss end*/

/*********************************************/
.onlinerev_mn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .onlinerev_outr {
    border-radius: 14px;
    box-shadow: 0px 0px 3px 2px #00000008;
  }
}
.onlinerev_rgt {
  width: 49%;
  background: #fff;
  padding: 10px;
  border-radius: 14px;
  box-shadow: 0px 0px 3px 2px #00000008;
}
.tblovrflw .card-body {
  max-height: 480px;
  overflow-y: auto;
}
.cale_lst {
  padding: 4px 16px;
  background: #fff;
  margin-right: 6px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 27px;
}

.calend_lft {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.calend_txt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cale_lst.active a {
  color: #030303;
}
.cale_lst a {
  color: #a5a5a5;
}
.pro_sttig .mat-form-field-infix {
  padding: 0;
}
.adm_prfl {
  align-items: center;
}
.pro_sttig .admin_setting_title {
  line-height: normal;
  margin-bottom: 0;
}
.crd_padd .headng figure {
  padding-right: 60px;
}
.crd_padd .headng h3 {
  font-size: 26px;
  line-height: 39px;
  font-weight: 600;
}

.add_edit .common-btn {
  display: flex;
  background: var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
  border-color: var(--primary-color);
  align-items: center;
}

.add_edit .common-btn figure {
  padding-right: 7px;
}

.pgnt_mn {
  padding-top: 30px;
}

.weburl h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  margin: 10px 0px !important;
}

.pro_sttig label.heading-setting-form {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 25px;
  color: #202020;
  font-weight: 600;
  padding-bottom: 6px;
}
.pro_sttig .outer_uploadsshdf.bigholder {
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
app-project-images .add_edit {
  margin: 20px 0px;
}
.pro_sttig .image_placeholder img {
  width: 100%;
}

.onlinerev_mn .onlinerev_outr .onrev_fdx {
  flex-wrap: wrap;
  position: relative;
  .item_select {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    border: 1px solid #e3e3e3;
    padding: 5px;
    border-radius: 4px;
  }
}

.daily_ordrs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mat-select {
    width: 35%;
    border: 1px solid #e3e3e3;
    padding: 5px;
    border-radius: 4px;
  }
}
.onlinerev_outr .onrev_fdx h2 {
  width: 100%;
}
.onlinerev_mn .dsh_img {
  width: 100%;
}
.pgn {
  display: flex;
  flex-wrap: wrap;
  p {
    margin-right: auto;
  }
}
.timepicker__header {
  padding: 15px 30px;
  background-color: $primary-color !important;
}
.clock-face__container {
  .clock-face__number > span.active {
    background-color: $primary-color !important;
    color: var(--clock-face-time-active-color);
  }
}
.timepicker-button {
  color: $primary-color !important;
}

.ul_fdx {
  li {
    position: relative;
    .slot_time {
      position: absolute;
      left: 150px;
      bottom: 10px;
      font-size: 18px;
      color: #978f8fa3;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.fill_clr path#Vector,
.fill_clr path#Vector-2 {
  fill: var(--primary-color);
}

.mrchnt {
  .multiselect-item-checkbox input + div {
    font-size: 14px !important;
  }
  .multiselect-item-checkbox input + div:before {
    border: 2px solid var(--primary-color) !important;
    border-radius: 3px !important;
    width: 12px !important;
    height: 12px !important;
  }
}

.fare_outr {
  width: 49%;
}

.trial_content {
  h3 {
    font-size: 20px;
    margin: 10px 0px;
    font-weight: 500;
    color: #000;
    line-height: normal;
    span {
      font-size: 16px;
      color: #706b6b;
      font-weight: 400;
      line-height: normal;
    }
  }
  p {
    margin: 0px 0px 10px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
.trial_btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

//Client Onboard Issue

.conta_iner {
  max-width: 1170px;
  width: 90%;
  padding: 0;
  margin: 0 auto;
}
img {
  max-width: 100%;
}

li {
  list-style: none;
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
ngx-intl-tel-input.form-control .iti.iti--allow-dropdown {
  width: 100% !important;
}
.fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
}
.outer_right input#phone {
  border: none;
}
ngx-intl-tel-input.form-control {
  padding: 0px 0px !important;
  height: auto !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary-color) !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

.required {
  color: #ff0000;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

html,
body {
  height: 100%;
}

.is-invalid {
  border-color: 1px solid #ff0000;
}

.toastr-success {
  background-color: #54d275 !important;
}

.to-message {
  word-wrap: break-word;
  font-size: 16px !important;
  line-height: 20px;
  color: #fff !important;
  background-color: transparent;
  font-weight: 500 !important;
  font-family: "Poppins";
}

#toastr-container > div {
  border-radius: 7px !important;
}

.all_sec {
  margin: 20px 0px;
}

h4.usr_titile {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

/*-------swaticss strat----------*/
span.text-warning.ng-star-inserted {
  font-size: 12px;
}
.user_title_inner {
  width: 100%;
}
.tabbing_top_innerright h3 {
  font-size: 14px;
  font-weight: 600;
}

.driver_tracing_deails figure {
  width: 35px;
  height: 35px;
  margin: 0px;
}
.bby h3 {
  font-size: 14px;
}
.user_details h6 {
  font-size: 12px;
  color: #514747;
}
.upper_details_inner {
  margin-top: 5px;
}
span.currency {
  font-size: 16px;
  color: var(--primary-color) !important;
  margin-right: 5px;
}
.amount {
  font-size: 16px;
}
.tabbing_top_innerleft {
  width: 100%;
}
.tabbing_top_innerright {
  width: 56%;
}
figure.d_cncl {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 6px;
  top: 2px;
}
li.grd_btn {
  margin: 0px 5px 0px 0px;
}

.form-control {
  font-size: 13px;
}
.up_file.up_single {
  display: flex;
  align-items: center;
  justify-content: center;
}
.up_file.up_single figure {
  width: 100px;
}
button.mat-raised-button.mat-primary.ng-star-inserted {
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 5px;
}
button.mat-raised-button.mat-warn.location_bck {
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
}
.trigger figure {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-item .mat-form-field-flex.ng-tns-c120-4 {
  display: none;
}
.dropdown-item p {
  font-size: 12px;
  font-weight: 400;
}
.dropdown-item:active {
  background-color: #e9ecef;
}
.dropdown-menu {
  padding: 5px;
}

.add_ussr .iti.iti--allow-dropdown,
.add_ussr input,
.add_ussr input#phone,
.add_ussr ngx-intl-tel-input,
.add_ussr ngx-intl-tel-input#phone {
  width: 100% !important;
}

.input-group {
  width: 100% !important;
}
ngx-intl-tel-input.ng-untouched.ng-invalid.ng-dirty {
  width: 100% !important;
}
.iti.iti--allow-dropdown input#Phone {
  width: 100% !important;
}
.iti.iti--allow-dropdown {
  width: 100%;
}

.grdn_btn .dropdown-item figure {
  width: 20px;
  height: 20px;
}

.grdn_btn .dropdown-item-rgt figure {
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
li.dropdown-item-rgt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 5px;
}

.btnn {
  border-radius: 5px;
  padding: 9px;
}

label.d-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0px;
}

.btm_fld .row {
  padding: 0;
}

.input-group span.add_mrebx {
  background: #ff00002b;
  padding: 8px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.add-on-btn {
  padding: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 35px;
  position: relative;
  background: #ffd4d4;
  top: 20px;
}

button.add-on-btn i.fa.fa-plus {
  color: var(--primary-color);
  font-size: 16px;
}

.rgt span.add_mrebx:first-child {
  background: #00800021;
  color: #008000cf;
}

.flxi-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-left: 16px;
}

.btm_fld .row {
  margin: 0px;
  padding: 0px;
}

span.dropdown.btn.btn-primary.ng-star-inserted.show,
span.dropdown.btn.btn-primary.show,
span.dropdown.import_icon.imex_ddwn.dropdown-toggle.btn.btn-primary,
span.dropdown.show,
.dropdown.notification_drp.show,
.drpdwn_action.dropdown-menu.h-auto.show {
  z-index: 11;
}

.my-element {
  display: inline-block;
  margin: 0 0.5rem;

  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

span.sidemenu-label:hover {
  margin-left: 5px;
  font-weight: bold !important;
  color: var(--primary-color);
}
.sidemenu-label {
  transition: margin-left 0.4s ease;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}
.catdata_dtl figure {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icn_box {
  background-color: transparent;
  height: 25px;
  width: 25px;
  text-align: center;
  z-index: -1;
}

.bottom_data span#dropdownMenuButton {
  position: absolute;
  top: -34px;
  right: 0;
}

.bottom_data.iconShow.lst_bdyscl.ng-star-inserted {
  position: relative;
}

ngx-intl-tel-input.ng-untouched.ng-dirty.ng-valid {
  width: 100%;
}

.dropdown-menu.cstm-cs.show {
  transform: translate3d(-141px, 35px, 0px) !important;
}

input#phone {
  width: 100%;
}

/*customer settings*/

.customer_togl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .togl_inr {
    width: 140px;
    display: flex;
    justify-content: center;
  }
}

.addons-fdx .trsh button {
  background: #fff;
  padding: 10px;
  box-shadow: 0 0 0 2px #0000000f;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  i.fa.fa-trash {
    color: #ff0000f2;
  }
}

/* Rajeena css */
.slct .mat-form-field-appearance-fill .mat-form-field-flex {
  background: none;
}
.dst .NgxEditor__MenuBar {
  flex-wrap: wrap;
}
.slct .mat-form-field-infix {
  padding: 0 !important;
}
.arbi {
  padding: 0;
  padding-right: 15px;
  max-width: none;
}
.arbi textarea {
  resize: none;
}
.crd {
  padding-top: 0 !important;
}
.flx {
  display: flex;
  align-items: stretch;
  gap: 30px;
}
.ad_ara {
  position: relative;
  top: -24px;
  padding: 0;
}
.ad_ara .cmn_frm .input-group {
  width: 245px !important;
}
.p-0 {
  padding: 0.375rem 0.75rem !important;
}
.fxd_dlv .mat-select-trigger {
  display: flex;
  gap: 70px;
}
.fxd_dlv .mat-select {
  width: 245px;
}
.ag_mp {
  padding: 0;
  height: 250px;
  width: 77vw;
}
.agm-maps {
  height: 250px !important;
}
.md_mp {
  padding: 0;
  top: 10px;
}
.image_placeholder figure {
  width: 300px;
  height: 240px;
}
.image_placeholder img {
  object-fit: contain;
}
/*customer settings end*/

/*********************************************/

/* Verified after mering with ui on 14 july time 11:15*/
/*Rocky responsive start*/

@media (max-width: 2000px) {
  .dsh_crd_bx {
    gap: 19px;
  }
  body.sidebar-toggled .sidebar-brand-icon {
    padding: 0;
    justify-content: center;
    figure {
      width: 50px;
      height: 50%;
      max-height: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  body.sidebar-toggled ul.navbar-nav.lft_nvbr {
    width: 75% !important;
  }
  ul.navbar-nav.lft_nvbr {
    width: 50%;
  }
  ul.navbar-nav.ryt_nvbr {
    justify-content: flex-end;
    width: 21%;
  }
  .cnt_img figure {
    width: 80px;
    height: 80px;
  }
  .dsh_bx.dsh_tbl tbody tr td {
    font-size: 14px;
  }
}

@media (max-width: 1800px) {
  .dsh_crd_bx {
    gap: 18px;
  }
}

@media (max-width: 1700px) {
  .dsh_crd_bx {
    gap: 17px;
  }
  .dsh_bx .dsh_fig {
    width: 40px;
    height: 40px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .multidsh #map {
    height: 395px;
  }
  .dsh_btm .dsh_cnt {
    width: 71%;
    top: 14px;
    right: 5px;
  }

  table.table.border_spacing td p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
  .onlinerev_outr .rev_closed h3 {
    font-size: 22px;
  }
  .bottom_dta .dsh_bx.dsh_tbl tbody tr td {
    font-weight: 500 !important;
  }
  .onlinerev_outr .rev_closed p {
    margin: unset;
  }
  .onlinerev_outr .onrev_fdx {
    margin: 10px 0px;
  }
  .onlinerev_outr .onrev_fdx h2 {
    font-size: 20px;
  }

  .onlinerev_outr .onrev_fdx h2 {
    margin-bottom: 10px !important;
  }

  .onlinerev_outr .rev_closed {
    padding: 10px;
  }
  .onrev_fdx .rev_closed figure {
    width: 20px;
    height: 26px;
  }
  .sidebar-toggled div#content-wrapper {
    width: 100% !important;
    padding-left: 80px !important;
  }
  .rev_map_fdx input {
    width: 46% !important;
  }
  .user_title h6 {
    font-size: 18px;
  }
  .search .srch_cn input {
    width: 300px !important;
    padding-left: 40px;
    padding-right: 30px;
  }
  .border_pd tbody td img {
    width: 45px;
    height: 45px;
  }

  ul.navbar-nav.lft_nvbr {
    width: 36%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 33%;
  }
  .dsh_bx h3 {
    font-size: 16px;
  }
  .dsh_bx p {
    font-size: 16px;
  }
  .dsh_crd_bx .dsh_bx {
    width: 24%;
  }

  .outer_uploadsshdf.bigpro {
    width: 120px;
    height: 120px;
  }
  .dsh_crd_bx {
    max-height: 250px;
  }
}

@media (max-width: 1600px) {
  .dsh_crd_bx {
    gap: 14px;
  }
  .dsh_crd_bx .dsh_bx .dsh_cnt p {
    font-size: 14px;
    white-space: nowrap;
    span {
      font-size: 16px;
    }
  }
  .crle_prgres svg {
    width: 125px;
    height: 125px;
  }
}

@media (max-width: 1500px) {
  .dsh_btm .dsh_cnt {
    width: 60%;
  }
  .bg-gradient-primary {
    width: 14%;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    font-size: 13px;
  }
  .table_view_outr .usr_tbl_fdx .top_btn {
    right: 10px;
  }
  .wrpr .table_view_outr p.usr_email {
    margin-bottom: 5px !important;
  }
  .table_view_outr .left_usr figure {
    width: 40px;
    height: 40px;
  }

  ul.navbar-nav.lft_nvbr {
    width: 47%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 29%;
  }
  .navbar-expand .navbar-nav .nav-link.prfl_bx .profile_image,
  .prfl_bx .profile_image {
    width: 35px;
    height: 35px;
  }

  .sidebar-brand-icon {
    figure {
      width: 110px;
    }
    button i {
      font-size: 20px;
      margin: 8px 0px 0px 5px;
    }
  }
  .sidebar .nav-item .nav-link {
    padding: 8px 20px;
  }
  .ltg_ds_sdbr h3 {
    font-size: 12px;
  }
  .navbar-expand .navbar-nav .nav-link i {
    font-size: 25px;
  }
  .zone_panel .mat-select-value {
    font-size: 13px;
  }
  .dsh_bx {
    p {
      font-size: 12px;
      line-height: normal;
    }
    button {
      font-size: 12px;
      padding: 5px 10px;
    }
    .dsh_tp button {
      font-size: 13px;
      font-weight: 700;
      img {
        width: 16px;
      }
    }
  }
  .cnt_img {
    padding: 13px 140px 13px 20px;
    figure {
      right: 20px;
      width: 90px;
    }
    p {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .dsh_crd_bx {
    .dsh_bx {
      h3 {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
      }
      .dsh_cnt {
        h2 {
          font-size: 16px;
        }
        p {
          figure {
            width: 13px;
            height: 15px;
            margin-left: 9px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .dsh_fig {
    max-width: 65%;
  }

  .dsh_btm .dsh_cnt {
    span {
      font-size: 12px;
      b {
        font-weight: 700;
        margin-right: 5px;
      }
    }
    h2 {
      font-size: 16px;
      figure {
        width: 24px;
        margin-right: 5px;
      }
    }
  }
  .dsh_bx.dsh_tbl {
    thead tr th {
      font-size: 14px;
      font-weight: 500;
    }
    tbody tr td {
      font-size: 12px;
      padding: 8px;
    }
  }
  .copyright {
    font-size: 20px;
  }
}
@media (max-width: 1400px) {
  .wrpr .table_view_outr .usr_lst_cls li {
    padding: 5px;
  }

  .wrpr .table_view_outr ul.usr_lst_cls li.accepted h5,
  .wrpr .table_view_outr ul.usr_lst_cls li.Cancelled h5,
  .wrpr .table_view_outr ul.usr_lst_cls li.Completed h5,
  .wrpr .table_view_outr li.memberShipPlan h5 {
    font-size: 11px !important;
  }

  .dsh_crd_bx {
    gap: 12px;
  }
  .dsh_btm .dsh_cnt {
    width: 57%;
  }
  .multidsh #map {
    height: 370px;
  }
  table.table.border_spacing td p {
    font-size: 14px;
  }
  .onlinerev_outr .rev_closed h3 {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  .rev_closed span {
    margin-left: 5px;
  }
  .onlinerev_outr .onrev_fdx h2 {
    font-size: 18px;
  }
  .bg-gradient-primary {
    width: 14%;
  }
  .sidebar .nav-item .nav-link {
    padding: 8px 8px;
  }
  .border_spacing tbody td {
    font-size: 14px;
  }
  li.nav-item a img {
    height: 18px;
  }
  .btn {
    font-size: 14px;
  }

  .elipises {
    right: 16px;
    z-index: 1;
  }
  .tittle_outer {
    margin-bottom: 10px;
  }
  ul.navbar-nav.lft_nvbr {
    width: 40%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 30%;
  }
  div#storeOptionBtn {
    transform: translate3d(0px, 32px, 0px) !important;
  }
  ul.navbar-nav.ryt_nvbr p.trial {
    margin-right: 12px;
    white-space: nowrap;
  }
  .dsh_crd_bx .dsh_bx h3 {
    font-size: 14px;
  }
  .dsh_crd_bx .dsh_bx .dsh_cnt h2 {
    font-size: 16px;
  }
  .border_spacing tbody td {
    font-size: 12px;
  }
}

@media (max-width: 1300px) {
  .bg-white.py-2.collapse-inner.rounded {
    margin: 0 5px 0 0px;
  }
  .dsh_crd_bx .dsh_bx {
    width: 32%;
  }
  .dsh_btm .dsh_cnt {
    width: 55%;
  }

  table.table.border_spacing td p {
    font-size: 12px;
  }

  .card .dataTable td,
  .card .table td,
  .card .dataTable th {
    padding: 8px 10px;
  }
  .table.border_spacing th {
    font-size: 14px;
  }
  .innr_social .tag_left h5 {
    font-size: 18px;
  }
  .onlinerev_outr .rev_closed h3 {
    font-size: 18px;
  }
  .onrev_fdx .rev_closed figure {
    margin: 5px 0px 0px 5px !important;
    width: 25px;
    height: 25px;
  }

  .sidebar .nav-item .nav-link {
    padding: 8px 8px;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    font-size: 12px;
  }
  .wrpr .table_view_outr .right_usr {
    width: 88%;
  }
  .wrpr .table_view_outr .usr_lst_cls li {
    margin-right: 5px;
  }
  ul.navbar-nav.lft_nvbr {
    width: 38%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 31%;
  }
  .navbar-expand .navbar-nav .nav-link {
    display: flex;
    align-items: baseline;
  }
  .dsh_crd_bx .dsh_bx {
    margin-bottom: 10px;
  }
  .table_view_outr .left_usr figure {
    width: 45px;
    height: 45px;
  }
  .table_view_outr .usr_tbl_fdx .top_btn {
    position: absolute;
    right: 2px;
    top: 3px;
  }
  .wrpr .table_view_outr .right_usr {
    padding-left: 0;
  }
  .elipises {
    right: 0;
  }
}

@media (max-width: 1200px) {
  .innr_social .tag_left h5 {
    font-size: 16px;
  }

  .outer_usr {
    width: 32%;
    margin: 0px 9px 9px 0px;
    padding: 5px 5px;
  }

  .outer_usr .usr_innr_fdx .right_usr_dtl {
    width: 100%;
    padding-left: 20px;
  }
  .outer_usr .usr_innr_fdx .right_usr_dtl h3 {
    font-size: 13px;
  }

  .left_usr_dtl figure {
    width: 50px;
    height: 50px;
  }
  .green-bg,
  button.green-bg {
    font-weight: 500;
  }
  .elipises {
    right: 4px;
    bottom: 42px;
    transform: unset;
  }

  #wrapper #content-wrapper {
    padding: 100px 10px 30px 10px;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    line-height: 20px;
  }
  .wrpr .table_view_outr .usr_tbl_fdx {
    width: 49.5%;
  }
  .wrpr .table_view_outr .usr_tbl_fdx {
    padding: 7px;
  }
  .wrpr .table_view_outr ul.usr_lst_cls li.Cancelled h5 {
    font-size: 12px !important;
  }
  .wrpr .table_view_outr ul.usr_lst_cls li.accepted h5 {
    font-size: 12px !important;
  }
  .wrpr .table_view_outr ul.usr_lst_cls li.Completed h5 {
    font-size: 12px !important;
  }
  .wrpr .table_view_outr li.memberShipPlan h5 {
    font-size: 12px !important;
  }
  .wrpr .table_view_outr .usr_lst_cls li {
    padding: 3px;
  }
  .sidebar-brand-icon figure {
    width: 100px;
    margin: 0 auto;
  }
  .wrpr .table_view_outr .usr_lst_cls li {
    margin-right: 5px;
  }
  .bg-gradient-primary {
    width: 20%;
  }
  .table_view_outr .left_usr figure {
    width: 40px;
    height: 40px;
  }

  .dsh_btm {
    flex-wrap: wrap;
  }

  .dsh_img {
    width: 100%;
  }

  .dsh_btm .dsh_cnt {
    width: 100%;
  }

  .dsh_bx.dash_pdn.type_spc {
    margin-top: 18px !important;
  }
  .dsh_crd_bx {
    max-height: 400px;
  }
  .dsh_crd_bx .dsh_bx h3 {
    font-size: 12px;
  }
  // .dsh_crd_bx .dsh_bx {
  //   // height: 75px;
  // }

  #wrapper #content-wrapper {
    margin-left: 20%;
  }

  .cnt_img {
    padding: 10px 90px 10px 15px;
  }

  .dsh_bx p {
    margin-top: 0;
  }

  .cnt_img figure {
    width: 60px;
  }
  .dsh_fig figure {
    width: 30px;
  }
  ul.navbar-nav.lft_nvbr {
    width: 38%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 37%;
  }

  .navbar-expand .navbar-nav .nav-link.prfl_bx .profile_image,
  .prfl_bx .profile_image {
    display: none;
  }
  .main_admin_details {
    flex-wrap: wrap;
  }

  .user_title h6 {
    font-size: 20px;
  }
  .onlinerev_outr .rev_closed h3 {
    font-size: 16px;
  }
  .onlinerev_outr .rev_closed p {
    font-size: 14px;
  }
  .filters_outer {
    flex-wrap: wrap;
  }
  .crle_prgres svg.ng-star-inserted {
    width: 124px;
  }
}

@media (max-width: 992px) {
  .crle_prgres svg.ng-star-inserted {
    width: 100px;
  }
  .customer_details .amount,
  .driver_customer_details .nav-tabs .nav-link {
    font-size: 14px;
  }
  .inner_content_tabbing {
    width: 100%;
  }
  .wrp_respsv {
    .srch_cn input {
      width: 235px !important;
    }
  }
  .table.border_spacing th {
    font-size: 14px;
  }
  .user_title h6 {
    font-size: 16px;
  }
  li.nav-item a img {
    height: 15px;
  }
  .sidebar li img {
    margin-right: 3px;
  }

  .search {
    align-items: center;
  }
  ul.navbar-nav.lft_nvbr {
    width: 45%;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 33%;
  }
  tr.cdk-drag input {
    width: 70% !important;
  }
  span.import_icon i:before {
    font-size: 12px;
  }
  .category_drx span.import_icon i:before {
    font-size: 14px;
  }

  .onlinerev_mn.col-md-12 {
    padding: 0px !important;
  }
  .onlinerev_outr .rev_closed {
    width: 100%;
  }
  .outer_usr {
    width: 48%;
  }
  .inner {
    display: flex;
    justify-content: space-between;
  }
  .container-fluid {
    padding: 0 !important;
  }
  .new_usr_wrap .user_fdx .right .new_usr_btn button {
    font-size: 14px;
  }

  .new_usr_wrap .user_fdx .right .grd_btn figure {
    width: 18px;
  }
  .user_fdx .srch_cn input {
    padding: 14px 15px 14px 30px;
    width: 230px;
  }
  a.order_value p {
    font-size: 12px;
  }
  .srch_cn .fa-search {
    left: 10px;
    font-size: 16px;
    top: 9px;
  }

  .wrpr .table_view_outr .usr_tbl_fdx {
    width: 48.5%;
  }

  .wrpr .table_view_outr {
    align-items: stretch;
  }
  .elipises {
    right: 10px;
    transform: rotate(90deg);
    bottom: 10px;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    line-height: 24px;
  }
  .wrpr .table_view_outr .right_usr ul {
    flex-wrap: wrap;
  }
  .wrpr .table_view_outr .usr_lst_cls li {
    margin: 0px 5px 5px 0px;
    width: 31%;
  }
  .wrpr .table_view_outr .usr_tbl_fdx {
    padding: 10px;
  }
  .wrpr .table_view_outr p.usr_email {
    margin-bottom: 8px !important;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    font-size: 14px;
  }

  .navbar-expand .navbar-nav .nav-link p b {
    font-weight: 500;
  }
  .mobile_res .web_btn {
    display: block;
    margin: auto;
    font-size: 12px;
    padding: 5px 20px;
  }
  ul.navbar-nav.ryt_nvbr.ntf_prfle p {
    margin-left: 15px;
    font-weight: 500;
    font-size: 12px;
  }

  .header-right button#dropdownMenuButton {
    transform: translate(3px, 0px);
  }
  .ltg_ds_sdbr li.nav-item a span {
    font-size: 12px;
  }
  .web_btn {
    display: none;
  }
  ul.navbar-nav.lft_nvbr {
    margin: 0 auto;
  }
  .sidebar .nav-item .nav-link {
    padding: 8px 10px;
  }
  .zone_panel .selector_div {
    width: 100px;
    margin-right: 0 !important;
  }
  .bg-gradient-primary {
    width: 20%;
  }
  .sidebar-brand-icon {
    height: 65px;
  }
  #wrapper #content-wrapper {
    padding: 100px 20px 30px 30px;
  }
  .dsh_bx.dash_pdn {
    margin-top: 20px;
  }
  .form-control {
    font-size: 12px;
  }
  .copyright {
    font-size: 16px;
  }
  .dsh_crd_lft,
  .dsh_crd_ryt {
    width: 100%;
  }
  .rlt .col-md-2 {
    padding-right: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .dsh_crd_bx .dsh_bx {
    width: 48%;
  }
  .dsh_fig {
    max-width: 40%;
  }
  .pdstore input {
    width: 190px !important;
  }
  #wrapper #content-wrapper {
    margin-left: 21%;
  }
  .admin_setting_inner p {
    font-size: 12px;
  }
  .innr_social .tag_left h5 {
    font-size: 14px;
  }
  .doc_container {
    margin-top: 10px;
  }
  .single_doc,
  tr.cdk-drag input {
    width: 49%;
  }
  .single_doc {
    margin: 0px 4px 10px 0px;
  }

  .matwrp {
    .mat-tab-label {
      height: 40px;
      padding: 0 10px;
    }
    mat-ink-bar.mat-ink-bar {
      width: 125px;
    }
  }

  .bg-white.py-2.collapse-inner.rounded {
    margin: 0 5px 0 5px;
  }
  ul.nav.nav-tabs li.nav-item {
    width: 14%;
  }

  .driver_customer_details .nav-tabs .nav-link {
    font-size: 12px;
    font-weight: 500;
  }

  ul.nav.nav-tabs li.nav-item a {
    padding: 10px 5px;
    text-align: center;
  }

  .copyright {
    font-size: 14px;
  }

  .green-bg,
  button.green-bg {
    padding: 3px 10px;
    border-radius: 3px;
  }
  .pagination_ui p {
    font-size: 12px;
  }

  .btn.btn-primary:before {
    width: 34%;
  }

  .btn_src {
    padding: 7px 5px !important;
  }

  tr.cdk-drag input {
    width: 50% !important;
  }

  figure.type_image {
    width: 35px;
    height: 35px;
    overflow: hidden;
  }

  figure.brands_img {
    width: 55px;
    height: 55px;
    margin: auto;
  }

  .onlinerev_outr {
    width: 100%;
    margin-bottom: 10px;
  }
  .outer_usr .right_usr_dtl .svg_fdx p {
    width: 140px;
  }

  .outer_usr .usr_innr_fdx .right_usr_dtl h3 {
    font-size: 12px;
  }

  .left_usr_dtl figure {
    width: 35px;
    height: 35px;
  }

  .container-fluid {
    padding: 0 !important;
  }
  li.nav-item a img {
    margin-right: 5px !important;
  }
  .green-bg,
  button.green-bg {
    font-size: 12px;
  }

  .wrpr .table_view_outr p.usr_email {
    font-size: 12px;
    margin: 5px 0px 10px 30px;
  }
  button.red_bg {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .wrpr .table_view_outr .right_usr ul li h6 {
    font-size: 12px;
  }
  .wrpr .table_view_outr .usr_lst_cls li {
    width: 46%;
  }

  .user_title h6 {
    font-size: 14px;
    font-weight: 500;
  }

  .new_usr_wrap .user_fdx .right .new_usr_btn button {
    border-radius: 4px;
  }
  span.import_icon i:before {
    font-size: 12px;
    border-radius: 4px;
  }
  .new_usr_wrap .user_fdx .right .grd_btn figure {
    width: 14px;
  }
  .user_fdx .srch_cn input {
    width: 140px;
    height: 35px;
  }
  .border_spacing thead tr {
    height: 50px;
  }
  .border_pd tbody td img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ul.navbar-nav.ryt_nvbr {
    width: 60%;
  }
  .border_spacing tbody td {
    font-size: 12px;
  }
  .table.table th,
  .table thead th {
    padding: 10px 5px;
    border: unset;
  }
  .table.border_spacing th {
    font-size: 12px;
  }
  .cat_ui .crd_padd {
    width: 100%;
  }
  ul.navbar-nav.lft_nvbr {
    width: 70%;
  }
  .bg-gradient-primary {
    width: 21%;
  }
  .inner_content_tabbing {
    width: 100%;
  }
  .navbar-nav {
    justify-content: flex-end;
  }
  #wrapper #content-wrapper {
    padding: 75px 12px 20px 20px;
  }
  .sidebar .nav-item .nav-link {
    padding: 5px 4px;
  }
  li.nav-item a img {
    height: 10px;
  }
  .dsh_bx h3 {
    font-size: 16px;
  }
  .ltg_ds_sdbr li.nav-item a span {
    font-size: 10px;
    font-weight: 500;
  }
  .filters_outer {
    flex-wrap: wrap;
  }
  .search.wrp_respsv {
    flex-wrap: wrap;
  }
  .ryt button.btn.btn-primary {
    margin-top: 10px;
  }
  .dsh_fig figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
  }
}

@media (max-width: 600px) {
  ul.navbar-nav.lft_nvbr {
    width: 100%;
  }
  .dsh_bx .dsh_fig {
    width: 30px;
    height: 30px;
  }
  .dsh_fig figure {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dsh_dtls h2 span {
    margin-left: 5px;
  }
  .dsh_dtls {
    width: 80%;
  }
  .dsh_bx {
    padding: 10px;
  }
  .dsh_crd_bx .dsh_bx .dsh_cnt p {
    font-size: 12px;
    white-space: nowrap;
  }
  .flx {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .request_ride_inner {
    height: 87% !important;
  }
  table.table .list_icon {
    width: 100%;
    margin: 0 auto;
  }
  .dsh_btm .dsh_cnt {
    width: 90%;
    top: 50px;
    left: unset;
    right: 0;
  }
  .single_doc,
  tr.cdk-drag input {
    width: 100%;
  }
  ul.nav.nav-tabs li.nav-item {
    width: 20%;
  }

  .driver_customer_details .nav-tabs .nav-link {
    font-size: 12px;
  }

  ul.nav.nav-tabs li.nav-item a {
    padding: 10px 5px;
    text-align: center;
  }

  .swal2-styled.swal2-confirm,
  .swal2-styled.swal2-cancel {
    font-size: 12px !important;
  }
  .swal2-icon.swal2-warning.swal2-icon-show {
    width: 30px !important;
    height: 30px !important;
  }
  .swal2-icon .swal2-icon-content {
    font-size: 32px !important;
  }
  div#swal2-content {
    font-size: 14px !important;
  }
  .swal2-popup {
    width: 300px !important;
  }

  .swal2-title {
    font-size: 14px !important ;
    margin-bottom: 5px !important;
  }

  .swal2-icon {
    margin: 10px 0px !important;
  }

  .filters_outer {
    flex-wrap: wrap;
    margin: 0;
  }

  .table_view_outr .left_usr {
    display: none;
  }
  figure.brands_img {
    width: 50px;
    height: 50px;
  }
  .wrpr .table_view_outr p.usr_email {
    font-size: 12px;
    margin: 0;
  }
  .wrpr .table_view_outr .usr_tbl_fdx {
    width: 100%;
  }
  .wrpr .table_view_outr .right_usr ul,
  .wrpr .table_view_outr .usr_tbl_fdx {
    flex-wrap: nowrap;
  }

  ul.navbar-nav.ryt_nvbr {
    width: 55%;
  }
  .srch_cn .fa-search {
    font-size: 14px;
  }
  .btn {
    font-size: 12px;
  }

  .dsh_bx.dsh_tbl tbody tr td {
    font-size: 12px;
    padding: 3px;
  }
  .cnclled_bordr {
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 10px;
  }

  .ltg_ds_sdbr li.nav-item a span {
    font-size: 10px;
  }

  li.nav-item a img {
    height: 12px;
  }

  .movires {
    display: block;
  }
  .web_view {
    display: none;
  }
  .sidebar-brand-icon {
    height: 60px;
    padding: 10px 5px;
  }

  .upper_details.lower_details {
    flex-wrap: wrap;
  }
  .new_usr_wrap .user_fdx .right .new_usr_btn button {
    border-radius: 4px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .dsh_crd_bx .dsh_bx {
    width: 100%;
  }
  .dsh_bx p {
    font-size: 10px;
  }
  .btn {
    font-size: 12px !important;
  }
  .child_svg {
    padding: 5px 15px;
  }
  .request_ride_inner .user_detail_fair {
    flex-wrap: wrap;
  }
  .cash_mo {
    margin-left: 0 !important;
  }
  ul.nav.nav-tabs li.nav-item {
    width: 25%;
  }
  td.product_img.center .man {
    width: 30px;
    height: 30px;
  }

  .search.wrp_respsv .srch_cn {
    width: 48%;
  }
  .wrp_respsv,
  .outer_usr,
  .user_fdx .right {
    width: 100%;
  }
  .btn {
    font-size: 12px;
  }
  #wrapper #content-wrapper {
    padding: 75px 12px 20px 10px;
  }
  .innr_social {
    flex-wrap: wrap;
    .tag_left,
    .tag_right {
      width: 100%;
    }
  }

  .ordr_side.active p {
    margin: 0;
  }
  .catbtn_ui {
    flex-direction: row;
  }
  .cnt_img p {
    font-size: 12px;
  }
  .copyright {
    font-size: 12px;
  }
  .green-bg,
  button.green-bg {
    padding: 3px 5px;
  }
  button.red_bg {
    padding: 2px 3px;
  }
  .user_fdx .srch_cn input {
    width: 163px;
  }
  .new_usr_wrap .user_fdx {
    flex-wrap: wrap;
  }

  .outer_usr {
    width: 100%;
  }

  .outer_usr .top_btn {
    margin-bottom: 0px;
  }

  .left_usr_dtl figure {
    width: 35px;
    height: 35px;
  }

  .outer_usr .usr_innr_fdx .right_usr_dtl {
    padding-left: 10px;
  }

  .outer_usr .usr_innr_fdx .left_usr_dtl {
    width: 10%;
  }

  figure.type_image,
  figure.brands_img {
    width: 35px;
    height: 35px;
    overflow: hidden;
  }

  .elipises {
    right: 5px;
    bottom: 13px;
  }

  .dsh_crd_bx .dsh_bx .dsh_cnt p {
    margin-left: 10px;
  }
  .navbar-expand .navbar-nav .nav-link.prfl_bx p b,
  .prfl_bx p b {
    font-size: 12px;
  }
  .dsh_crd_bx .dsh_bx .dsh_cnt h2 {
    font-size: 14px;
  }
  .dsh_crd_bx .dsh_bx .dsh_cnt p span {
    font-weight: 500;
  }
  #wrapper #content-wrapper {
    margin-left: 12%;
  }
  .sidebar .nav-item .nav-link {
    padding: 10px 20px;
  }

  .bg-gradient-primary {
    width: 14%;
  }

  li.nav-item a img {
    height: 18px;
    margin: 0 auto;
  }

  .ltg_ds_sdbr li.nav-item a span {
    display: none;
  }

  .search .srch_cn input {
    width: 190px !important;
    margin-left: 0;
    border-radius: 5px;
    padding-left: 25px;
  }

  .border_spacing thead tr {
    height: 40px;
  }
  .srch_cn .fa-search {
    font-size: 12px;
  }
  .table.border_spacing th {
    font-size: 12px;
  }
  .user_title h6 {
    font-size: 14px;
    font-weight: 500;
  }
  .border_spacing tbody td {
    font-size: 12px;
  }
  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type="tel"] {
    height: 35px !important;
  }
  .table.table th,
  .table thead th {
    padding: 10px 7px;
  }
  ul.navbar-nav.ryt_nvbr {
    width: 118%;
  }
  .category_drx {
    width: 50%;
  }
  .ngx-pagination a {
    padding: 0px 7px !important;
  }
  li.pagination-next {
    margin-left: 2px;
  }
  .ngx-pagination .disabled {
    padding: 0px 7px !important;
    margin-right: 6px;
  }
  .ngx-pagination .current {
    padding: 14px 15px !important;
    width: 30px;
    height: 30px;
  }
  .inner_content_tabbing,
  .main_admin_details p,
  .main_admin_details h2 :nth-child(2) .tabbing_top_innerleft h3,
  .main_admin_details h2,
  .main_admin_status h3,
  .pagination_ui p {
    font-size: 12px;
  }
  .order_type {
    padding: 10px;
    height: 25px;
    font-size: 10px;
  }
  .new_btn_ui {
    font-size: 14px;
  }
}

/*Rocky responsive end*/
/* Verified after mering with ui on 14 july time 11:15*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}
button.slick-next.slick-arrow {
  background: transparent;
  box-shadow: 0px 3px 6px #0000002e;
  width: 37px;
  height: 37px;
  border-radius: 20%;
  transition: all 400ms linear;
  position: absolute;
  right: -33px;
  z-index: 9999;
  overflow: visible;
}
button.slick-prev.slick-arrow {
  background: transparent;
  box-shadow: 0px 3px 6px #0000002e;
  width: 37px;
  height: 37px;
  border-radius: 20%;
  transition: all 400ms linear;
  position: absolute;
  left: -40px;
  z-index: 9999;
  overflow: visible;
}
button.slick-next.slick-arrow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0px;
  background: url(../img/right-arrow.png) no-repeat;
  background-size: 17px;
  width: 17px;
  height: 17px;
  margin: auto;
}
button.slick-prev.slick-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0px;
  background: url(../img/left-arrow.png) no-repeat;
  background-size: 17px;
  width: 17px;
  height: 17px;
  margin: auto;
}

.fdx-count {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  .temp_outr {
    width: 23%;
    padding: 20px 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 1px 2px #0000000a;
  }
  .rgt {
    span.add_mrebx {
      padding: 5px 8px;
      border-radius: 2px;
      cursor: pointer;
    }
    span.add_mrebx:last-child {
      background: #e0384f21;
    }
  }
  .fdx h5 {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: normal;
  }
}

/*------tooltip css-------------*/
.grdn_btn .tooltip {
  background: #fff;
  bottom: 85%;

  left: -5px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  color: var(--primary-color);
  border-radius: 3px;
  font-size: 12px;
  line-height: 3px;
  transform: rotate(90deg);
  transition: all 0.4s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.grdn_btn .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.grdn_btn .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid var(--primary-color) 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 56%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.grdn_btn:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

.grd_btn .tooltip {
  background: #fff;
  bottom: 85%;
  display: block;
  left: -5px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  color: var(--primary-color);
  border-radius: 3px;
  font-size: 10px;
  line-height: 3px;
  transform: rotate(90deg);
  transition: all 0.4s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.grd_btn .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.grd_btn .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid var(--primary-color) 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 55%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.grd_btn:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .grdn_btn .tooltip {
  display: none;
}

.toltp .tooltip {
  background: #fff;
  bottom: 85%;
  display: block;
  left: -12px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  color: var(--primary-color);
  border-radius: 3px;
  font-size: 10px;
  line-height: 3px;
  transform: rotate(90deg);
  transition: all 0.4s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.toltp .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.toltp .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid var(--primary-color) 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 56%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.toltp:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

.togl_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
}

/* IE can just show/hide with no transition */
.lte8 .grdn_btn .tooltip {
  display: none;
}

.lte8 .grdn_btn:hover .tooltip {
  display: block;
}
span.combine {
  position: absolute;
  right: 20px;
  top: -28px;
}

/*new dshboard */
.full_wd {
  width: 100%;
}

.lnt_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 160px;
  .lnt_lft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    figure {
      margin: 0;
      width: 160px;
    }
  }
}

.dsh_crd_bx .dsh_bx .dsh_cnt p figure {
  margin: 0;
  background: #50d59869;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60%;
  }
}

.dsh_dtls {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  h2 {
    font-size: 24px;
    font-weight: 400;
    color: #202020;
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

h3.dsh_hdd {
  font-size: 22px;
  font-weight: 500;
  margin: 20px 0px !important;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 80%;
    height: 1px;
    background: #d8d8d8;
    top: 12px;
    left: 20%;
    z-index: 0;
  }
  span {
    background: #f9f9f9;
    z-index: 1;
    position: relative;
  }
}

.stats_main {
  .mat-expansion-panel {
    margin-bottom: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
  }
  .mat-slide-toggle {
    margin-right: 10px;
  }

  .btm_fld {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    justify-content: flex-start;
  }
}

li.query_w {
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg {
    width: 30px;
    height: 30px;
  }
}
/*new dshboard end*/
/*For taxi */

.taxi_ordr {
  .taxibb {
    display: flex !important;
    width: 50%;
  }
  div#home {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
  .bookings_outr {
    width: unset !important;
  }
}

/*Taxi ends*/
li.query_w {
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
}

/*slots css*/

.table.cl_tbl {
  .mat-expansion-panel {
    box-shadow: unset;
  }
  .labl_days {
    width: 6%;
  }
  mat-expansion-panel-header .mat-content {
    gap: 10px;
  }
}

/*User Documents */
.doc_fd {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .apdv_btns {
    display: flex;
    gap: 10px;
  }
}

/*edit slots css*/

.edit_slots {
  .ui_input {
    position: relative;
    i.fa.fa-clock-o.slot_time {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}

/*Customer Setting css */

.customer_togl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .togl_inr {
    width: 140px;
    display: flex;
    justify-content: center;
  }
}
.addons-fdx {
  align-items: center;
  .trsh button {
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 0px 2px #0000000f;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    i.fa.fa-trash {
      color: #ff0000eb;
    }
  }
}
.del_btn {
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 15px;
  background: #9e9e9e45;
  i.fa.fa-trash {
    color: #f70505e0;
  }
}

.slots_face {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
